// src/App.js
import React, { useState, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useSearchParams, useParams  } from 'react-router-dom';
//import { ThemeProvider } from 'styled-components';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import Organization from './components/Organization';
import CheckoutPage from './components/CheckoutPage';
import CallManagement from './pages/CallManagement';
import CRMIntegration from './pages/CRMIntegration';
import UserManagement from './pages/UserManagement';
import Subscription from './pages/Subscription';
import 'bootstrap/dist/css/bootstrap.min.css';
//import { Container } from 'react-bootstrap';
import { isAuthenticated } from './utils/auth';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import AssistantList from './components/AssistantList';
import AssistantForm from './components/AssistantForm';
import LiveAssistantEdit from './components/LiveAssistantEdit';
import AppointmentSettings from './components/AppointmentSettings';
import AppointmentSetter from './components/AppointmentSetter';
import AppointmentSetterOld from './components/AppointmentSetterOld';
import CancelAppointment from './components/CancelAppointment';
import RescheduleAppointment from './components/RescheduleAppointment'
import GoogleAuthComponent from './components/GoogleAuthComponent'
import { OrganizationProvider } from './contexts/OrganizationContext';
import CallSummaryComponent from './components/CallSummaryComponent';
import CallTrack from './components/CallTrack';
import MonthlyBilling from './components/MonthlyBilling';
import PaymentHistoryComponent from './components/PaymentHistoryComponent';
import StripePaymentComponent from './components/StripePaymentComponent';
import PricingSection from './components/PricingSection'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { OrganizationContext } from './contexts/OrganizationContext';
import ThankYou from './components/ThankYou';
import BookingWidget from './components/BookingWidget';
import VapiPhoneNumbers from './components/VapiPhoneNumbers';
import VapiInboundCalls from './components/VapiInboundCalls';
import OutboundDial from './components/OutboundDial';
import UploadFiles from './components/UploadFiles';

/* const theme = {
  primary: '#BB86FC',
  background: '#121212',
  surface: '#1E1E1E',
  text: '#ffffff',
};
 */

const PrivateRoute = ({ children }) => {
  return isAuthenticated() ? <Layout>{children}</Layout> : <Navigate to="/login" />;
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
console.log('Stripe Key Available:', !!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// Create a wrapper component for Stripe Elements
const StripeWrapper = ({ children }) => (
  <Elements stripe={stripePromise}>
    {children}
  </Elements>
);

function App() {

  //const [isAdmin, setIsAdmin] = useState(false); // Set to true for testing
  const optionrganizationId = 'krupa9840'; 
  //const { organizationId } = useContext(OrganizationContext);
  //const { organizationId } = useContext(OrganizationProvider); 
  
  
  return (
    // <ThemeProvider theme={theme}>
    <OrganizationProvider>
      <Router>
        {/* <Container fluid> */}
          <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<Navigate to="/dashboard" />} />

        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route 
          path="/organization" 
          element={<PrivateRoute>
                      <Organization />
                   </PrivateRoute>} />

        <Route 
          path="/checkout/*" 
          element={<PrivateRoute>
           <StripeWrapper>
            <CheckoutPage />
           </StripeWrapper> 
          </PrivateRoute>}/>

                   
        <Route
          path="/call-summary"
          element={
            <PrivateRoute>
              <CallSummaryComponent />
            </PrivateRoute>
          }
        />
        <Route
          path="/call-track"
          element={
            <PrivateRoute>
              <CallTrack />
            </PrivateRoute>
          }
        />
        <Route 
          path="/monthly-billing" element={
            <PrivateRoute>
              <MonthlyBilling/>
            </PrivateRoute>
          } 
        />
        <Route 
          path="/pricing" element={
              <PricingSection/>
          } 
        />

        {/* Update the stripe-payment-component route */}
        <Route 
            path="/stripe-payment-component/*" 
            element={
              <PrivateRoute>
                <StripeWrapper>
                  <StripePaymentComponent />
                </StripeWrapper>
              </PrivateRoute>
            } 
          />

        <Route 
          path="/payment-history-component" element={
            <PrivateRoute>
              <PaymentHistoryComponent/>
            </PrivateRoute>
          } 
        />

        <Route
          path="/crm-integration"
          element={
            <PrivateRoute>
              <CRMIntegration />
            </PrivateRoute>
          }
        />
        <Route path="/vapi-phone-numbers" element={
            <PrivateRoute><VapiPhoneNumbers/> </PrivateRoute>} />
        <Route path="/vapi-inbound-calls" element={
            <PrivateRoute><VapiInboundCalls/> </PrivateRoute>} />
        <Route path="/vapi-outbound-calls" element={
            <PrivateRoute><OutboundDial/> </PrivateRoute>} />
        <Route path="upload-files" element={
            <PrivateRoute><UploadFiles/> </PrivateRoute>} />
        <Route path="/vapi-assistants" element={
            <PrivateRoute><AssistantList/> </PrivateRoute>} />
        <Route path="/vapi-assistants/create" element={
           <PrivateRoute><AssistantForm/></PrivateRoute>} />
        <Route path="/vapi-assistants/edit/:id" element={
            <PrivateRoute><AssistantForm/></PrivateRoute>} />

        <Route path="/vapi-assistants-live" element={
            <PrivateRoute><AssistantList/> </PrivateRoute>} />
        <Route path="/vapi-assistants-live/edit/:assistantId" element={
            <PrivateRoute><LiveAssistantEdit/></PrivateRoute>} />

        <Route path="/appointments" element={
            <PrivateRoute><AppointmentSettings/> </PrivateRoute>} />
        <Route path="/appointment-setter-v1" element={
            <AppointmentSetterOld/> } />  

        <Route path="/thankyou" element={    
            <ThankYou/> } />

        <Route path="/appointment-setter" element={
            <PrivateRoute><AppointmentSetter/> </PrivateRoute>} />
        <Route path="/cancel-appointment/:appointmentId" element={
            <CancelAppointmentPage/> } />
        <Route path="/reschedule-appointment/:appointmentId" element={
            <RescheduleAppointmentPage/> } />
        <Route path="/google-auth" element={
            <PrivateRoute><GoogleAuthComponent/> </PrivateRoute>} />
        <Route path="/booking-widget" element={
          <PrivateRoute><BookingWidget/> </PrivateRoute>} />



        <Route
          path="/subscriptions"
          element={
            <PrivateRoute>
              <Subscription />
            </PrivateRoute>
          }
        />
        <Route
          path="/user-management"
          element={
            <PrivateRoute>
              <UserManagement />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
    </OrganizationProvider>


//    </ThemeProvider>
  );
}

// Create wrapper components to handle URL parameters
const AppointmentSetterPage = () => {
  const [searchParams] = useSearchParams();
  const organizationId = searchParams.get('organizationId');


  return (
    <AppointmentSetter 
    showContactForm={true}
/>
  );
};


const CancelAppointmentPage = () => {
/*   const [searchParams] = useSearchParams();
  const appointmentId = searchParams.get('appointmentId');
  //const dateTime = searchParams.get('dateTime');
  // const timezone = searchParams.get('timezone');

  if (!appointmentId 
    //|| !dateTime 
    // || !timezone
  ) {
    return <div>Invalid appointment link</div>;
  }

  return (
    <CancelAppointment 
    appointmentId={appointmentId}
      appointmentDateTime={new Date(dateTime)}
       clientTimezone={timezone}
      onCancelSuccess={() => {
        alert('Appointment cancelled successfully');
        // Optionally redirect to a confirmation page
      }}
     />
  );
 */
  const { appointmentId } = useParams();

  if (!appointmentId) {
      return <div>Invalid appointment link</div>;
  }

  return (
      <div className="container mt-4">
          <CancelAppointment />
      </div>
  );
};

const RescheduleAppointmentPage = () => {
/*   const [searchParams] = useSearchParams();
  const appointmentId = searchParams.get('appointmentId');
  const dateTime = searchParams.get('dateTime');
  //const timezone = searchParams.get('timezone');

  if (!appointmentId || !dateTime 
    //|| !timezone
    ) {
    return <div>Invalid appointment link</div>;
  }

  return (
    <RescheduleAppointment 
    appointmentId={appointmentId}
      currentAppointment={new Date(dateTime)}
      //clientTimezone={timezone}
      //onRescheduleSuccess={() => {
      //  alert('Appointment rescheduled successfully');
        // Optionally redirect to a confirmation page
      //}}
    />
  );
 */
  const { appointmentId } = useParams();

  if (!appointmentId) {
      return <div>Invalid appointment link</div>;
  }

  return (
      <div className="container mt-4">
          <RescheduleAppointment />
      </div>
  );
};

export default App;