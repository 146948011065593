import React, { useState, useEffect, useContext } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { checkWebAvailability, bookWebAppointment, getAppointmentSettings } from '../services/api';
import '../styles/appointment-styles.css';

import { 
    format, 
    addDays, 
    startOfMonth, 
    endOfMonth, 
    eachDayOfInterval, 
    parseISO, 
    setHours, 
    setMinutes, 
    isSameMinute, 
    isAfter, 
    isBefore, 
    isToday, 
    getDay, 
    startOfWeek, 
    endOfWeek 
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { ChevronLeft, ChevronRight, Clock } from 'lucide-react';


const AppointmentSetter = ({ 
    organizationId,
    clientTimezone: initialTimezone,
    onAppointmentSelected,
    showContactForm = true
}) => {
    const { organizationIdContext } = useContext(OrganizationContext);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [timeSlots, setTimeSlots] = useState([]);
    const [selectedTime, setSelectedTime] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [clientName, setClientName] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [clientPhone, setClientPhone] = useState('');
    const [clientTimezone, setClientTimezone] = useState(
        initialTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const [bookingOpenNoDays, setBookingOpenNoDays] = useState(null);
    const [weekendDays, setWeekendDays] = useState([]);

    useEffect(() => {
        if (!organizationId) {
            organizationId = organizationIdContext;
        }
        if (selectedDate && organizationId) {
            fetchTimeSlots();
            fetchAppointmentSettings();
        }
    }, [selectedDate, organizationId, clientTimezone]);

    const fetchAppointmentSettings = async () => {
        try {
            const settings = await getAppointmentSettings(organizationId);
            setBookingOpenNoDays(settings.bookingOpenNoDays);
            setWeekendDays(settings.weekendDays);
        } catch (error) {
            console.error('Error fetching appointment settings:', error);
            setError('Failed to fetch appointment settings. Please try again.');
        }
    };

    const fetchTimeSlots = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const utcDate = new Date(Date.UTC(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate(),
                0, 0, 0
            ));

            const slots = await checkWebAvailability(
                organizationId, 
                utcDate.toISOString(), 
                clientTimezone
            );

            const parsedSlots = slots.map(slot => ({
                ...slot,
                dateTime: typeof slot.dateTime === 'string' ? new Date(slot.dateTime) : slot.dateTime
            }));

            setTimeSlots(parsedSlots);
            setSelectedTime(null);
        } catch (error) {
            console.error('Error fetching time slots:', error);
            setError('Failed to fetch time slots. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleDateClick = (date) => {
        setSelectedDate(date);
        setSelectedTime(null);
    };

    const handleTimeClick = (time) => {
        setSelectedTime(time);
    };

    const handleBookAppointment = async (e) => {
        if (e) {
            e.preventDefault();
        }

        if (selectedDate && selectedTime && organizationId && clientName && clientEmail && clientPhone) {
            try {
                const date = new Date();
                const tzOffset = -date.getTimezoneOffset();
                const clientDate = new Date(date.toLocaleString('en-US', { timeZone: clientTimezone }));
                const clientOffset = (clientDate - date) / (60 * 1000);
                const offsetDiff = clientOffset - tzOffset;

                const appointmentTime = new Date(Date.UTC(
                    selectedDate.getFullYear(),
                    selectedDate.getMonth(),
                    selectedDate.getDate(),
                    selectedTime.getHours(),
                    selectedTime.getMinutes() - offsetDiff,
                    0
                ));

                await bookWebAppointment(
                    organizationId,
                    appointmentTime.toISOString(),
                    clientName,
                    clientEmail,
                    clientPhone,
                    clientTimezone
                );
                
                alert('Appointment booked successfully!');
                fetchTimeSlots();
            } catch (error) {
                console.error('Error booking appointment:', error);
                alert('Failed to book appointment. Please try again.');
            }
        } else {
            alert('Please fill in all required fields.');
        }
    };

    const isDateSelectable = (date) => {
        const today = new Date();
        const maxDate = addDays(today, bookingOpenNoDays || 30);
        const isWeekend = weekendDays.includes(getDay(date));
        return isAfter(date, today) && isBefore(date, maxDate) && !isWeekend;
    };

    const renderTimeSlots = () => {
        if (isLoading) {
            return <div className="loading-message">Loading available times...</div>;
        }

        if (error) {
            return <div className="error-message">{error}</div>;
        }

        const availableSlots = timeSlots.filter(slot => slot.available);

        if (availableSlots.length === 0) {
            return <div className="no-slots-message">No time slots available for the selected date.</div>;
        }

        return (
            <div className="time-slots">
                {availableSlots.map(slot => (
                    <button
                        key={slot.dateTime.toISOString()}
                        className={`time-slot ${selectedTime && isSameMinute(slot.dateTime, selectedTime) ? 'selected' : ''}`}
                        onClick={() => handleTimeClick(slot.dateTime)}
                    >
                        <Clock size={16} />
                        {formatInTimeZone(slot.dateTime, clientTimezone, 'h:mm a')}
                    </button>
                ))}
            </div>
        );
    };

    const renderCalendar = () => {
        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(currentMonth);
        const calendarStart = startOfWeek(monthStart);
        const calendarEnd = endOfWeek(monthEnd);
        const dateRange = eachDayOfInterval({ start: calendarStart, end: calendarEnd });

        return (
            <div className="calendar-container">
                <div className="calendar-header">
                    <h2>{format(currentMonth, 'MMMM yyyy')}</h2>
                    <div className="calendar-nav">
                        <button onClick={() => setCurrentMonth(addDays(currentMonth, -30))}>
                            <ChevronLeft size={24} />
                        </button>
                        <button onClick={() => setCurrentMonth(addDays(currentMonth, 30))}>
                            <ChevronRight size={24} />
                        </button>
                    </div>
                </div>

                <div className="calendar-grid">
                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
                        <div key={day} className="calendar-day-header">{day}</div>
                    ))}
                    {dateRange.map(date => (
                        <button
                            key={date.toISOString()}
                            className={`calendar-day
                                ${date.getMonth() !== currentMonth.getMonth() ? 'other-month' : ''}
                                ${date.toDateString() === selectedDate.toDateString() ? 'selected' : ''}
                                ${!isDateSelectable(date) ? 'disabled' : ''}
                                ${isToday(date) ? 'today' : ''}
                                ${weekendDays.includes(getDay(date)) ? 'weekend' : ''}
                            `}
                            onClick={() => isDateSelectable(date) && handleDateClick(date)}
                            disabled={!isDateSelectable(date)}
                        >
                            {format(date, 'd')}
                        </button>
                    ))}
                </div>

                {renderTimeSlots()}

                {showContactForm ? (
                    <form onSubmit={handleBookAppointment} className="contact-form">
                        <div className="form-group">
                            <label>Name</label>
                            <input
                                type="text"
                                value={clientName}
                                onChange={(e) => setClientName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input
                                type="email"
                                value={clientEmail}
                                onChange={(e) => setClientEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Phone</label>
                            <input
                                type="tel"
                                value={clientPhone}
                                onChange={(e) => setClientPhone(e.target.value)}
                                required
                            />
                        </div>
                        <button 
                            type="submit" 
                            className="action-button"
                            disabled={!selectedDate || !selectedTime || !clientName || !clientEmail || !clientPhone}
                        >
                            Schedule Appointment
                        </button>
                    </form>
                ) : (
                    selectedTime && (
                        <button 
                            className="action-button"
                            onClick={() => onAppointmentSelected(selectedTime)}
                        >
                            Confirm New Time
                        </button>
                    )
                )}
            </div>
        );
    };

    return (
        <div className="appointment-setter">
            {renderCalendar()}
        </div>
    );
};

export default AppointmentSetter;