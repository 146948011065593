import React, { useState, useEffect, useContext } from 'react';
import { getPaymentHistory, getUpcomingPayments } from '../services/api';
import { OrganizationContext } from '../contexts/OrganizationContext';
import '../styles/PaymentHistory.css';

const PaymentHistoryComponent = () => {
  const { organizationId } = useContext(OrganizationContext);     
  const [pastPayments, setPastPayments] = useState([]);
  const [upcomingPayments, setUpcomingPayments] = useState([]);
  const [upcomingPaymentsMessage, setUpcomingPaymentsMessage] = useState('');

  useEffect(() => {
    const fetchPaymentData = async () => {
      const history = await getPaymentHistory(organizationId);
      setPastPayments(history);

      const upcoming = await getUpcomingPayments(organizationId);
      if (Array.isArray(upcoming)) {
        setUpcomingPayments(upcoming);
        setUpcomingPaymentsMessage('');
      } else if (upcoming && upcoming.message) {
        setUpcomingPayments([]);
        setUpcomingPaymentsMessage(upcoming.message);
      }
    };

    fetchPaymentData();
  }, [organizationId]);

  const formatDate = (timestamp) => {
    if (!timestamp) return 'Date not available';
    
    try {
      // Convert timestamp to milliseconds if it's in seconds
      const date = typeof timestamp === 'number' 
        ? new Date(timestamp * 1000) 
        : new Date(timestamp);

      // Check if date is valid
      if (isNaN(date.getTime())) {
        return 'Invalid date';
      }

      // Format date
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }).format(date);
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Date error';
    }
  };

  const formatAmount = (amount) => {
    if (amount == null) return 'Amount not available';
    
    try {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(amount);
    } catch (error) {
      console.error('Error formatting amount:', error);
      return 'Amount error';
    }
  };

  return (
    <div>
      <h2>Payment History</h2>
      {pastPayments.length > 0 ? (
        <div className="payment-list">
          <h3>Past Payments</h3>
          <table className="payment-table">
            <thead>
              <tr>
                <th>Amount</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {pastPayments.map(payment => (
                <tr key={payment.id} className={`payment-row ${payment.status}`}>
                  <td className="amount">{formatAmount(payment.amount)}</td>
                  <td className="date">{formatDate(payment.paymentDate)}</td>
                  <td className="status">{payment.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No past payments found.</p>
      )}


      <h2>Upcoming Payments</h2>
      {upcomingPaymentsMessage ? (
        <p>{upcomingPaymentsMessage}</p>
      ) : (Array.isArray(upcomingPayments) && upcomingPayments.length > 0) ? (
        <div className="payment-list">
          <table className="payment-table">
            <thead>
              <tr>
                <th>Amount</th>
                <th>Due Date</th>
              </tr>
            </thead>
            <tbody>
              {upcomingPayments.map((payment, index) => (
                <tr key={index} className="payment-row">
                  <td className="amount">{formatAmount(payment.amount)}</td>
                  <td className="date">
                    {payment.dueDate ? formatDate(payment.dueDate) : 'Due date not set'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>{typeof upcomingPayments === 'string' ? upcomingPayments : 'No upcoming payments found.'}</p>
      )}
    </div>
  );
};

export default PaymentHistoryComponent;