import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format, addDays, startOfMonth, endOfMonth, eachDayOfInterval, isSameMinute, 
         isAfter, isBefore, isToday, getDay, startOfWeek, endOfWeek  } from 'date-fns';
import { formatInTimeZone,zonedTimeToUtc, toZonedTime, fromZonedTime } from 'date-fns-tz';
import { Form, Button, Alert, Spinner, Card } from 'react-bootstrap';
import { getAppointmentById, checkWebAvailability, rescheduleAppointmentById } from '../services/api';
import ThankYou from './ThankYou';

import './AppointmentSetter.css';

const RescheduleAppointment = () => {
    const { appointmentId } = useParams();
    const navigate = useNavigate();
    const [appointment, setAppointment] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [timeSlots, setTimeSlots] = useState([]);
    const [selectedTime, setSelectedTime] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [weekendDays, setWeekendDays] = useState([]);
    const [clientTimezone, setClientTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [message, setMessage] = useState('');
    const [showThankYou, setShowThankYou] = useState(false);
    const [bookedAppointment, setBookedAppointment] = useState(null);    

    const timezones = [
        'America/New_York',
        'America/Chicago',
        'America/Denver',
        'America/Los_Angeles',
        'Europe/London',
        'Europe/Paris',
        'Asia/Tokyo',
        'Australia/Sydney',
    ];

    // Load appointment details on component mount
    useEffect(() => {
        loadAppointmentDetails();
    }, [appointmentId]);

    const changeClientTimezone = async (newTimezone) => {
        setClientTimezone(newTimezone);
        // Refetch time slots with new timezone
        if (selectedDate && appointment) {
            setIsLoading(true);
            try {
                const utcDate = new Date(Date.UTC(
                    selectedDate.getFullYear(),
                    selectedDate.getMonth(),
                    selectedDate.getDate(),
                    0, 0, 0
                ));

                const slots = await checkWebAvailability(
                    appointment.organization.id,
                    utcDate.toISOString(),
                    newTimezone
                );

                const parsedSlots = slots.map(slot => ({
                    ...slot,
                    dateTime: typeof slot.dateTime === 'string' ? new Date(slot.dateTime) : slot.dateTime
                }));
                setTimeSlots(parsedSlots);
                setSelectedTime(null);
            } catch (error) {
                setError('Failed to fetch time slots for new timezone');
            } finally {
                setIsLoading(false);
            }
        }
    };


    // Load time slots when date is selected
    useEffect(() => {
        if (selectedDate && appointment) {
            fetchTimeSlots();
        }
    }, [selectedDate, appointment, clientTimezone]); // Add clientTimezone as a dependency

    const loadAppointmentDetails = async () => {
        try {
            console.log('loadAppointmentDetails');
            const data = await getAppointmentById(appointmentId);
            setAppointment(data);
            setSelectedDate(new Date(data.appointmentDateTime));
            setCurrentMonth(new Date(data.appointmentDateTime));
            setClientTimezone(data.timezone);
            setMessage(data.message || '');
            // Set weekend days based on organization settings if available
            setWeekendDays(data.organization.weekendDays || [0, 6]); // Default to Saturday and Sunday
        } catch (error) {
            navigate('/appointment-setter');
            //setError('Failed to load appointment details. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchTimeSlots = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const utcDate = new Date(Date.UTC(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate(),
                0, 0, 0
            ));

            const slots = await checkWebAvailability(
                appointment.organization.id,
                utcDate.toISOString(),
                clientTimezone // Use current clientTimezone instead of appointment.timezone
            );

            const parsedSlots = slots.map(slot => ({
                ...slot,
                dateTime: typeof slot.dateTime === 'string' ? new Date(slot.dateTime) : slot.dateTime
            }));
            setTimeSlots(parsedSlots);
            setSelectedTime(null);
        } catch (error) {
            setError('Failed to fetch available time slots. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleDateClick = (date) => {
        setSelectedDate(date);
        setSelectedTime(null);
    };

    const handleTimeClick = (time) => {
        setSelectedTime(time);
    };

    const handleReschedule = async () => {
        if (selectedDate && selectedTime && clientTimezone) {
            if (!window.confirm('Are you sure you want to reschedule this appointment?')) {
                return;
            }
    
            try {

                // Create a date string in the client's timezone
                const hours = selectedTime.getHours();
                const minutes = selectedTime.getMinutes();

                // Format the date string for the client's timezone
                const dateString = `${format(selectedTime, 'yyyy-MM-dd')}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;
                console.log('Reschedule dateString:',dateString);

                const utcDate = fromZonedTime(dateString, clientTimezone);
                console.log('Reschedule utcDate:',utcDate);

                console.log('Reschedule utcDate.toISOString:',utcDate.toISOString());
                setIsLoading(true);
                setError(null);
    
                const response = await rescheduleAppointmentById(
                    appointmentId,
                    utcDate.toISOString(),
                    clientTimezone,
                    message
                );
    

            

            if (response) {
            // Create appointment object for ThankYou component
            const appointmentData = {
                ...appointment,
                appointmentDateTime: selectedTime.toISOString(),
                timezone: clientTimezone,
                message: message,
                contact: {
                    ...appointment.contact
                }                
            };
            setBookedAppointment(appointmentData);
            setShowThankYou(true);
            //alert('Appointment rescheduled successfully!');
            //navigate('/'); // Or wherever you want to redirect after success
            } else {
                setError('Failed to reschedule appointment. Please try again.');
            }
        } catch (error) {
            setError('An error occurred while rescheduling the appointment.',error);
        } finally {
            setIsLoading(false);
        }
    } else {
        alert('Please fill in all required fields.');
    }        
    };

    if (showThankYou && bookedAppointment) {
        return <ThankYou type="rescheduled" appointment={bookedAppointment} />;
    }


    const isDateSelectable = (date) => {
        const today = new Date();
        const maxDate = addDays(today, appointment?.organization?.bookingOpenNoDays || 30);
        const isWeekend = weekendDays.includes(getDay(date));
        return isAfter(date, today) && isBefore(date, maxDate) && !isWeekend;
    };

    const renderCalendar = () => {
        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(currentMonth);
        const calendarStart = startOfWeek(monthStart);
        const calendarEnd = endOfWeek(monthEnd);
        const dateRange = eachDayOfInterval({ start: calendarStart, end: calendarEnd });

        return (
            <div className="calendar">
                <div className="calendar-header">
                    <button onClick={() => setCurrentMonth(addDays(currentMonth, -30))}>&lt;</button>
                    <h2>{format(currentMonth, 'MMMM yyyy')}</h2>
                    <button onClick={() => setCurrentMonth(addDays(currentMonth, 30))}>&gt;</button>
                </div>
                <div className="calendar-grid">
                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
                        <div key={day} className="calendar-day-header">{day}</div>
                    ))}
                    {dateRange.map(date => (
                        <div
                            key={date.toISOString()}
                            className={`calendar-day 
                                ${date.getMonth() !== currentMonth.getMonth() ? 'other-month' : ''} 
                                ${date.toDateString() === selectedDate?.toDateString() ? 'selected' : ''}
                                ${!isDateSelectable(date) ? 'disabled' : ''}
                                ${isToday(date) ? 'today' : ''}
                                ${weekendDays.includes(getDay(date)) ? 'weekend' : ''}
                            `}
                            onClick={() => isDateSelectable(date) && handleDateClick(date)}
                        >
                            {format(date, 'd')}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderTimeSlots = () => {
        if (isLoading) {
            return <p>Loading time slots...</p>;
        }

        if (error) {
            return <p className="error">{error}</p>;
        }

        const availableSlots = timeSlots.filter(slot => slot.available);

        if (availableSlots.length === 0) {
            return <p>No time slots available for the selected date.</p>;
        }

        return (
            <div className="time-slots">
                {availableSlots.map(slot => (
                    <button
                        key={slot.dateTime.toISOString()}
                        className={`time-slot ${selectedTime && isSameMinute(slot.dateTime, selectedTime) ? 'selected' : ''}`}
                        onClick={() => handleTimeClick(slot.dateTime)}
                    >
                        {format(new Date(slot.dateTime), 'h:mm a')}
                    </button>
                ))}
            </div>
        );
    };

    if (isLoading && !appointment) {
        return <Spinner animation="border" />;
    }

    if (error && !appointment) {
        return <Alert variant="danger">{error}</Alert>;
    }

    if (!appointment) {
        return <Alert variant="warning">Appointment not found</Alert>;
    }

    return (
        <div className="reschedule-appointment">
            <Card>
                <Card.Header>
                    <h1>Reschedule Appointment</h1>
                </Card.Header>
                <Card.Body>
                    <div className="current-appointment mb-4">
                        <h4>Current Appointment Details</h4>
                        <p>Date: {format(new Date(appointment.appointmentDateTime), 'PPPP')}</p>
                        <p>Time: {format(new Date(appointment.appointmentDateTime), 'h:mm a')}</p>
                        <p>Contact: {appointment.contact.name}</p>
                        <p>Email: {appointment.contact.email}</p>
                        <Form.Group className="mb-3">
                        <Form.Label>Timezone</Form.Label>
                        <Form.Select
                            value={clientTimezone}
                            onChange={(e) => changeClientTimezone(e.target.value)}
                        >
                            {timezones.map(tz => (
                                <option key={tz} value={tz}>{tz}</option>
                            ))}
                        </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                        <Form.Label>Message (Optional)</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Add any additional notes or requests"
                        />
                        </Form.Group>
                    </div>

                    <div className="new-appointment">
                        <h4>Select New Date and Time</h4>
                        <div className="appointment-selection">
                            {renderCalendar()}
                            {renderTimeSlots()}
                        </div>
                    </div>

                    {error && <Alert variant="danger" className="mt-3">{error}</Alert>}

                    <Button 
                        variant="primary"
                        onClick={handleReschedule}
                        disabled={!selectedTime || !clientTimezone || isLoading}
                        className="mt-3"
                    >
                        {isLoading ? 'Rescheduling...' : 'Confirm Reschedule'}
                    </Button>
                </Card.Body>
            </Card>
        </div>
    );
};

export default RescheduleAppointment;