// UploadFiles.js
import React, { useState, useEffect, useContext } from 'react';
import { Form, Button, Alert, Table, Spinner, Badge } from 'react-bootstrap';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { uploadFile, getUploadedFiles } from '../services/api';


const UploadFiles = () => {
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  
  const { organizationId } = useContext(OrganizationContext);

  useEffect(() => {
    if (organizationId) {
      fetchFiles();
    }
  }, [organizationId]);

  const fetchFiles = async () => {
    try {
      const data = await getUploadedFiles(organizationId);
      setFiles(data);
      setError(null);
    } catch (err) {
      setError(err.message);
      setFiles([]);
    }
  };

  // UploadFiles.js
const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) return;

    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
        const maxSize = 10 * 1024 * 1024; // 10MB
        if (selectedFile.size > maxSize) {
            throw new Error('File size exceeds 10MB limit');
        }

        console.log('Uploading file:', selectedFile.name, 'Size:', selectedFile.size);
        
        await uploadFile(selectedFile, organizationId);
        
        setSuccess(true);
        setSelectedFile(null);
        event.target.reset();
        await fetchFiles();
    } catch (err) {
        console.error('Upload error details:', err);
        setError(err.message || 'Failed to upload file');
    } finally {
        setLoading(false);
    }
};

const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        const validTypes = [
            'text/markdown',
            'application/pdf',
            'text/plain',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ];
        
        if (!validTypes.includes(file.type)) {
            setError('Invalid file type. Please upload Markdown, PDF, plain text, or Microsoft Word files only.');
            setSelectedFile(null);
            event.target.value = null;
            return;
        }

        const maxSize = 10 * 1024 * 1024; // 10MB in bytes
        if (file.size > maxSize) {
            setError('File size exceeds 10MB limit');
            setSelectedFile(null);
            event.target.value = null;
            return;
        }
        
        setSelectedFile(file);
        setError(null);
    }
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const renderFileTable = () => (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>File Name</th>
          <th>Original Name</th>
          <th>Type</th>
          <th>Size</th>
          <th>Status</th>
          <th>Uploaded At</th>
        </tr>
      </thead>
      <tbody>
        {files.map(file => (
          <tr key={file.id}>
            <td>
              {file.url ? (
                <a href={file.url} target="_blank" rel="noopener noreferrer">
                  {file.name}
                </a>
              ) : (
                file.name
              )}
            </td>
            <td>{file.originalName}</td>
            <td>{file.mimetype}</td>
            <td>{formatFileSize(file.bytes)}</td>
            <td>
              <Badge bg={file.status === 'indexed' ? 'success' : 'warning'}>
                {file.status}
              </Badge>
            </td>
            <td>{new Date(file.createdAt).toLocaleString()}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  return (
    <div className="container">
      <h2 className="mb-4">Upload Files to Knowledge Base</h2>
      
      <Form onSubmit={handleSubmit} className="mb-4">
        <Form.Group className="mb-3">
          <Form.Label>Select File</Form.Label>
          <Form.Control
            type="file"
            onChange={handleFileChange}
            accept=".md,.pdf,.txt,.doc,.docx"
          />
          <Form.Text className="text-muted">
            Supported formats: Markdown, PDF, plain text, or Microsoft Word (.doc and .docx)
          </Form.Text>
        </Form.Group>
        
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">File uploaded successfully!</Alert>}
        
        <Button 
          type="submit" 
          variant="primary"
          disabled={!selectedFile || loading}
        >
          {loading ? (
            <>
              <Spinner animation="border" size="sm" className="me-2" />
              Uploading...
            </>
          ) : (
            'Upload File'
          )}
        </Button>
      </Form>

      <h3 className="mb-3">Uploaded Files</h3>
      {files.length === 0 ? (
        <Alert variant="info">No files uploaded yet.</Alert>
      ) : (
        renderFileTable()
      )}
    </div>
  );
};

export default UploadFiles;