import React, { useState, useEffect, useContext } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { getCallSummariesByOrganization, getSubscriptionStartDate } from '../services/api';
import { format } from 'date-fns';
import {
  Table,
  Pagination,
  Alert,
  Card,
  Accordion,
  Button,
} from 'react-bootstrap';
import { TextField, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import '../styles/CallSummary.css';

const CallSummaryComponent = () => {
  const [summaries, setSummaries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSummary, setSelectedSummary] = useState(null);
  const { organizationId } = useContext(OrganizationContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [assistantId, setAssistantId] = useState('');
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(null);

  useEffect(() => {
    const fetchSubscriptionDate = async () => {
      if (organizationId) {
        try {
          const subscriptionDate = await getSubscriptionStartDate(organizationId);
          setSubscriptionStartDate(new Date(subscriptionDate));
        } catch (error) {
          console.error('Error fetching subscription start date:', error);
        }
      }
    };

    fetchSubscriptionDate();
  }, [organizationId]);

  useEffect(() => {
    fetchSummaries();
  }, [organizationId, month, year, assistantId, currentPage]);

  const fetchSummaries = async () => {
    try {
      setLoading(true);
      const data = await getCallSummariesByOrganization(organizationId, { 
        month: month || undefined, 
        year: year || undefined, 
        assistantId: assistantId || undefined, 
        page: currentPage, 
        size: 10
      });
      setSummaries(data);
    } catch (err) {
      setError('Failed to fetch call summaries. Please try again later.');
      console.error('Error fetching summaries:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
    setCurrentPage(1);
  };

  const handleYearChange = (e) => {
    setYear(e.target.value);
    setCurrentPage(1);
  };

  const handleAssistantIdChange = (e) => {
    setAssistantId(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderFilters = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 2019 + 1 }, (_, i) => 2020 + i);
    const months = [
      { value: '01', label: 'January' },
      { value: '02', label: 'February' },
      { value: '03', label: 'March' },
      { value: '04', label: 'April' },
      { value: '05', label: 'May' },
      { value: '06', label: 'June' },
      { value: '07', label: 'July' },
      { value: '08', label: 'August' },
      { value: '09', label: 'September' },
      { value: '10', label: 'October' },
      { value: '11', label: 'November' },
      { value: '12', label: 'December' },
    ];

    return (
      <div className="mb-4">
        <FormControl fullWidth margin="normal">
          <InputLabel>Assistant ID</InputLabel>
          <TextField 
            value={assistantId} 
            onChange={handleAssistantIdChange} 
            fullWidth 
            placeholder="Enter Assistant ID"
          />
        </FormControl>
        {subscriptionStartDate && (
          <>
            <FormControl fullWidth margin="normal">
              <InputLabel>Month</InputLabel>
              <Select value={month} onChange={handleMonthChange} fullWidth>
                <MenuItem value="">All Months</MenuItem>
                {months.map((m) => (
                  <MenuItem key={m.value} value={m.value}>
                    {m.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Year</InputLabel>
              <Select value={year} onChange={handleYearChange} fullWidth>
                <MenuItem value="">All Years</MenuItem>
                {years.map((y) => (
                  <MenuItem key={y} value={y}>
                    {y}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </div>
    );
  };

  const formatDuration = (minutes) => {
    const hrs = Math.floor(minutes / 60);
    const mins = Math.floor(minutes % 60);
    const secs = Math.floor((minutes * 60) % 60);
    return `${hrs}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const safelyParseJSON = (jsonString) => {
    try {
      return typeof jsonString === 'string' ? JSON.parse(jsonString) : jsonString;
    } catch (error) {
      return null;
    }
  };

  const formatChatLogs = (content) => {
    if (!content) return 'No logs available';
    
    const cleanContent = content.replace(/^"|"$/g, '');
    const lines = cleanContent.split('\n').filter(line => line.trim());
    
    return (
      <div className="chat-logs">
        {lines.map((line, index) => {
          const isAssistant = line.startsWith('Assistant:');
          const isUser = line.startsWith('User:');
          
          return (
            <div 
              key={index} 
              className={`chat-message ${isAssistant ? 'assistant' : ''} ${isUser ? 'user' : ''}`}
            >
              {line}
            </div>
          );
        })}
      </div>
    );
  };

  const formatContent = (content, contentType) => {
    if (!content) return 'No content available';
    
    switch (contentType) {
      case 'json':
        const parsedJson = safelyParseJSON(content);
        if (parsedJson) {
          return (
            <pre className="formatted-json">
              {JSON.stringify(parsedJson, null, 2)}
            </pre>
          );
        }
        return formatChatLogs(content);
        
      case 'chat':
        return formatChatLogs(content);
        
      case 'text':
      default:
        return <pre className="formatted-text">{content}</pre>;
    }
  };

  const renderSummaryDetails = (summary) => (
    <Card className="mt-4 mb-4">
      <Card.Header>
        <h4>Call Details - {format(new Date(summary.callTrack.startTime), 'PPpp')}</h4>
      </Card.Header>
      <Card.Body>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Summary</Accordion.Header>
            <Accordion.Body>
              {formatContent(summary.summary, 'text')}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Transcript</Accordion.Header>
            <Accordion.Body>
              {formatContent(summary.transcript, 'text')}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>Analysis</Accordion.Header>
            <Accordion.Body>
              {formatContent(summary.analysis, 'json')}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>Call Logs</Accordion.Header>
            <Accordion.Body>
              {formatContent(summary.callLogs, 'chat')}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {summary.recordingUrl && (
          <div className="mt-4">
            <h5>Recording</h5>
            <audio controls className="w-100">
              <source src={summary.recordingUrl} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        )}
      </Card.Body>
    </Card>
  );

  if (loading) {
    return <div className="text-center p-4">Loading call summaries...</div>;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <div className="container-fluid py-4">
      <h2 className="mb-4">Call Summaries</h2>
      
      {renderFilters()}

      {summaries.length === 0 ? (
        <Alert variant="info">No call summaries available.</Alert>
      ) : (
        <>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Date</th>
                <th>Duration</th>
                <th>Cost</th>
                <th>Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {summaries.map((summary) => (
                <tr key={summary.id}>
                  <td>{format(new Date(summary.callTrack.startTime), 'PP')}</td>
                  <td>{formatDuration(summary.callTrack.durationInMinutes)}</td>
                  <td>${summary.callTrack.cost.toFixed(2)}</td>
                  <td>{summary.callTrack.callType}</td>
                  <td>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => setSelectedSummary(
                        selectedSummary?.id === summary.id ? null : summary
                      )}
                    >
                      {selectedSummary?.id === summary.id ? 'Hide Details' : 'View Details'}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Pagination className="mt-4">
            {[...Array(totalPages).keys()].map((page) => (
              <Pagination.Item
                key={page + 1}
                active={page + 1 === currentPage}
                onClick={() => handlePageChange(page + 1)}
              >
                {page + 1}
              </Pagination.Item>
            ))}
          </Pagination>

          {selectedSummary && renderSummaryDetails(selectedSummary)}
        </>
      )}
    </div>
  );
};

export default CallSummaryComponent;