import React, { useState, useEffect, useContext } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { checkWebAvailability, bookWebAppointment, getAppointmentSettings } from '../services/api';
import { format, addDays, startOfMonth, endOfMonth, eachDayOfInterval, parseISO, setHours, setMinutes, isSameMinute, isAfter, isBefore, isToday, getDay, startOfWeek, endOfWeek  } from 'date-fns';
import { formatInTimeZone, fromZonedTime, toZonedTime, format as formatTz } from 'date-fns-tz';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Form, Button, Alert, Container, Row, Col, Spinner } from 'react-bootstrap';
import ThankYou from './ThankYou';
import './AppointmentStyles.css';

const AppointmentSetterOld = () => {
    const { organizationId } = useContext(OrganizationContext);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [timeSlots, setTimeSlots] = useState([]);
    const [selectedTime, setSelectedTime] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [clientName, setClientName] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [clientPhone, setClientPhone] = useState('');
    const [clientTimezone, setClientTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [bookingOpenNoDays, setBookingOpenNoDays] = useState(null);
    const [weekendDays, setWeekendDays] = useState([]);
    const [eventName, setBookingEventName] = useState('');
    const [meetingDurationMinutes, setMeetingDurationMinutes] = useState('');
    const [message, setMessage] = useState('');
    const [showThankYou, setShowThankYou] = useState(false);
    const [bookedAppointment, setBookedAppointment] = useState(null);



    useEffect(() => {
        if (selectedDate && organizationId) {
            fetchTimeSlots(selectedDate);
            fetchAppointmentSettings();
        }
    }, [selectedDate, organizationId]);

    const fetchAppointmentSettings = async () => {
        try {
            const settings = await getAppointmentSettings(organizationId);
            setBookingOpenNoDays(settings.bookingOpenNoDays);
            console.log('settings.weekendDays='+settings.weekendDays);
            setWeekendDays(settings.weekendDays);
            setBookingEventName(settings.eventName);
            setMeetingDurationMinutes(settings.meetingDurationMinutes);

        } catch (error) {
            console.error('Error fetching appointment settings:', error);
            setError('Failed to fetch appointment settings. Please try again.');
        }
    };    

    const isDateSelectable = (date) => {
        const today = new Date();
        const maxDate = addDays(today, bookingOpenNoDays);
        const isWeekend = weekendDays.includes(getDay(date)); // getDay returns 0 for Sunday, 6 for Saturday
        return isAfter(date, today) && isBefore(date, maxDate) && !isWeekend;    };

    const timezones = [
        'America/New_York',
        'America/Chicago',
        'America/Denver',
        'America/Los_Angeles',
        'Europe/London',
        'Europe/Paris',
        'Asia/Tokyo',
        'Australia/Sydney',
        // Add more timezones as needed
    ];   
    
    const changeClientTimezone = async (newTimezone) => {
        setClientTimezone(newTimezone);
        await fetchTimeSlots(newTimezone);
    };

    const fetchTimeSlots = async (timezone = clientTimezone) => {
        setIsLoading(true);
        setError(null);
        try {
            // Use the selected date at midnight UTC
            const utcDate = new Date(Date.UTC(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate(),
                0, 0, 0
            ));
            console.log('utcDate ='+utcDate+' clientTimezone='+clientTimezone);            
            const slots = await checkWebAvailability(
                organizationId, 
                utcDate.toISOString(), 
                clientTimezone
            );

            const parsedSlots = slots.map(slot => ({
                ...slot,
                dateTime: typeof slot.dateTime === 'string' ? new Date(slot.dateTime) : slot.dateTime

            }));
            setTimeSlots(parsedSlots);
            setSelectedTime(null); // Reset selected time when fetching new slots
        } catch (error) {
            console.error('Error fetching time slots:', error);
            setError('Failed to fetch time slots. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };



    const handleDateClick = (date) => {
        setSelectedDate(date);
        setSelectedTime(null);
    };

    const handleTimeClick = (time) => {
        setSelectedTime(time);
    };



    const handleBookAppointment = async () => {
        if (selectedDate && selectedTime && organizationId && clientName && clientEmail && clientPhone && clientTimezone) {
            try {
                // Create a date string in the client's timezone
                const hours = selectedTime.getHours();
                const minutes = selectedTime.getMinutes();

                // Format the date string for the client's timezone
                const dateString = `${format(selectedTime, 'yyyy-MM-dd')}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;
                console.log('bookWebAppointment dateString:',dateString);

                const utcDate = fromZonedTime(dateString, clientTimezone);
                console.log('bookWebAppointment utcDate:',utcDate);

                console.log('bookWebAppointment utcDate.toISOString:',utcDate.toISOString());
                setIsLoading(true);
                setError(null);


                const result = await bookWebAppointment(
                    organizationId,
                    utcDate.toISOString(),
                    clientName,
                    clientEmail,
                    clientPhone,
                    clientTimezone,
                    message
                );

                // Create appointment object for ThankYou component
                const appointmentData = {
                    appointmentDateTime: utcDate.toISOString(),
                    contact: {
                        name: clientName,
                        email: clientEmail,
                        phone: clientPhone
                    },
                    timezone: clientTimezone,
                    message: message
                };

                setBookedAppointment(appointmentData);
                //alert('Appointment booked successfully!');
                setShowThankYou(true);
                //fetchTimeSlots();
            } catch (error) {
                console.error('Error booking appointment:', error);
                alert('Failed to book appointment. Please try again.');
            }
        } else {
            alert('Please fill in all required fields.');
        }
    };

    if (showThankYou && bookedAppointment) {
        return <ThankYou type="booked" appointment={bookedAppointment} />;
    }


    const renderCalendar = () => {
        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(currentMonth);
        const calendarStart = startOfWeek(monthStart);
        const calendarEnd = endOfWeek(monthEnd);
        const dateRange = eachDayOfInterval({ start: calendarStart, end: calendarEnd });

        return (
            <Card className="mb-4">
                <Card.Body>
                <div className="calendar">    
                <div className="calendar-header">
                    <button onClick={() => setCurrentMonth(addDays(currentMonth, -30))}>&lt;</button>
                    <h2>{format(currentMonth, 'MMMM yyyy')}</h2>
                    <button onClick={() => setCurrentMonth(addDays(currentMonth, 30))}>&gt;</button>
                </div>
                    <div className="calendar-grid">
                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
                        <div key={day} className="calendar-day-header">{day}</div>
                    ))}
                    {dateRange.map(date => (
                        <div
                            key={date.toISOString()}
                            className={`calendar-day 
                                ${date.getMonth() !== currentMonth.getMonth() ? 'other-month' : ''} 
                                ${date.toDateString() === selectedDate?.toDateString() ? 'selected' : ''}
                                ${!isDateSelectable(date) ? 'disabled' : ''}
                                ${isToday(date) ? 'today' : ''}
                                ${weekendDays.includes(getDay(date)) ? 'weekend' : ''}
                            `}
                            onClick={() => isDateSelectable(date) && handleDateClick(date)}
                        >
                            {format(date, 'd')}
                        </div>
                    ))}
                </div>
                </div>
                </Card.Body>
            </Card>
        );
    };

    const renderTimeSlots = () => {
        if (isLoading) {
            return <Spinner animation="border" className="d-block mx-auto" />;
        }

        if (error) {
            return <Alert variant="danger">{error}</Alert>;
        }

        const availableSlots = timeSlots.filter(slot => slot.available);

        if (availableSlots.length === 0) {
            return <Alert variant="info">No time slots available for the selected date.</Alert>;
        }

        return (
            <Row className="g-2">
                {availableSlots.map(slot => (
                    <Col xs={6} sm={4} md={3} key={slot.dateTime.toISOString()}>
                        <Button
                            variant={selectedTime && isSameMinute(slot.dateTime, selectedTime) ? 'primary' : 'outline-primary'}
                            className="w-100"
                            onClick={() => handleTimeClick(slot.dateTime)}
                        >
                            {format(new Date(slot.dateTime), 'h:mm a')}
                        </Button>
                    </Col>
                ))}
            </Row>
        );
    };

    return (
        <Container className="py-4">
            <Card>
                <Card.Header>
                    <h2 className="h4 mb-0">Schedule Appointment</h2>
                </Card.Header>
                <Card.Body>
                    {organizationId ? (
                        <>
                            <Row className="mb-4">
                                <Col>
                                    <h3 className="h5">{eventName}</h3>
                                    <p className="text-muted">{meetingDurationMinutes}</p>
                                    <p>{format(selectedDate, 'EEE, MMM d, yyyy')}</p>
                                </Col>
                            </Row>

                            <Form.Group className="mb-4">
                                <Form.Label>Timezone</Form.Label>
                                <Form.Select 
                                    value={clientTimezone}
                                    onChange={(e) => changeClientTimezone(e.target.value)}
                                >
                                    {timezones.map(tz => (
                                        <option key={tz} value={tz}>{tz}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            {renderCalendar()}
                            {renderTimeSlots()}

                            <Form className="mt-4">
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                value={clientName}
                                                onChange={(e) => setClientName(e.target.value)}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control 
                                                type="email"
                                                value={clientEmail}
                                                onChange={(e) => setClientEmail(e.target.value)}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control 
                                                type="tel"
                                                value={clientPhone}
                                                onChange={(e) => setClientPhone(e.target.value)}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Form.Group className="mb-3">
                                    <Form.Label>Message (Optional)</Form.Label>
                                    <Form.Control 
                                        as="textarea"
                                        rows={3}
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                    />
                                </Form.Group>

                                <div className="d-grid">
                                    <Button
                                        variant="primary"
                                        onClick={handleBookAppointment}
                                        disabled={!selectedDate || !selectedTime || !clientName || !clientEmail || !clientPhone || !clientTimezone || isLoading}
                                    >
                                        {isLoading ? (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    className="me-2"
                                                />
                                                Booking...
                                            </>
                                        ) : (
                                            'Book Appointment'
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        </>
                    ) : (
                        <Spinner animation="border" className="d-block mx-auto" />
                    )}
                </Card.Body>
            </Card>
        </Container>
    );/*  : (
                <p>Loading organization information...</p>
            )}
        </div>
    ); */
};

export default AppointmentSetterOld;