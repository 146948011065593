import React, { useState, useContext, useEffect } from 'react';
//import { AuthContext } from '../../contexts/AuthContext';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../../services/api';
import { setAuthToken, isAuthenticated, setUser } from '../../utils/auth';
import { Form, Button, Alert, Container, Row, Col } from 'react-bootstrap';
import { OrganizationContext } from '../../contexts/OrganizationContext';


const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { refreshOrganization } = useContext(OrganizationContext);

  //const [verificationCode, setVerificationCode] = useState('');
  //const [step, setStep] = useState(1);
  //const { login } = useContext(AuthContext);


  useEffect(() => {
    if (isAuthenticated()) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const handleRegister = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      // Register user
      const data = await registerUser(email, password);
      
      // Set auth token and user
      setAuthToken(data.accessToken);
      setUser(email);

      // Refresh organization context
      await refreshOrganization();

      // Navigate to organization setup
      navigate('/organization');
    } catch (error) {
      setError('Failed to Register. Please check your credentials.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="app-container">
      <Row className="justify-content-md-center mt-5">
        <Col md={6}>
          <h2 className="text-center mb-4">Register</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleRegister}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100">
              Register
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );

};

export default Register;
