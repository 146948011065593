// src/pages/Dashboard.js
import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';

const Dashboard = () => {
  return (
    <>
      <h1 className="mb-4">Dashboard</h1>
      <Row>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Active Configurations</Card.Title>
              <Card.Text>5</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Total Calls</Card.Title>
              <Card.Text>1,234</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Active Subscriptions</Card.Title>
              <Card.Text>3</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* Add more dashboard widgets as needed */}
    </>
  );
};
export default Dashboard;