import React, { useState, useEffect, useContext } from 'react';
import { Table, Button, Alert, Modal, Form } from 'react-bootstrap';
import { getSubscriptions, deleteSubscription, createSubscription } from '../services/api';
import { OrganizationContext } from '../contexts/OrganizationContext';

const Subscription = () => {
  const { organizationId } = useContext(OrganizationContext);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newSubscription, setNewSubscription] = useState({ plan: '', status: '', startDate: '' });

  useEffect(() => {
    fetchSubscriptions();
  }, [organizationId]);

  const fetchSubscriptions = async () => {
    try {
      if (organizationId) {
          setLoading(true);
          const fetchedSubscriptions = await getSubscriptions(organizationId);
          setSubscriptions(fetchedSubscriptions);
          setLoading(false);
      }
    } catch (error) {
      console.error('Failed to fetch subscriptions:', error);
      setError('Failed to fetch subscriptions. Please try again later.');
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to cancel this subscription?')) {
      try {
        await deleteSubscription(id);
        fetchSubscriptions();
      } catch (error) {
        console.error('Failed to delete subscription:', error);
        setError('Failed to delete subscription. Please try again.');
      }
    }
  };

  const handleCreateSubscription = async (e) => {
    e.preventDefault();
    try {
      await createSubscription(newSubscription);
      setShowModal(false);
      setNewSubscription({ plan: '', status: '', startDate: '' });
      fetchSubscriptions();
    } catch (error) {
      console.error('Failed to create subscription:', error);
      setError('Failed to create subscription. Please try again.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <div>
      <h1 className="mb-4">Subscriptions</h1>
      <Button variant="primary" onClick={() => setShowModal(true)} className="mb-3">
        Add New Subscription
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Plan</th>
            <th>Active</th>
            <th>Start Date</th>
            <th>Subscription Amount</th>
            <th>Call Amount</th>
            <th>Frequency</th>

            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {subscriptions.map(subscription => (
            <tr key={subscription.id}>
              <td>{subscription.subscriptionPlan}</td>
              <td>{subscription.isActive}</td>
              <td>{subscription.startDate}</td>
              <td>{subscription.subscriptionAmount}</td>
              <td>{subscription.coveredCallAmount}</td>
              <td>{subscription.subscriptionFreq}</td>

              <td>
                <Button variant="danger" size="sm" onClick={() => handleDelete(subscription.id)}>
                  Cancel
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleCreateSubscription}>
            <Form.Group className="mb-3">
              <Form.Label>Plan</Form.Label>
              <Form.Control 
                as="select"
                value={newSubscription.plan} 
                onChange={(e) => setNewSubscription({...newSubscription, plan: e.target.value})}
                required
              >
                <option value="">Select a plan</option>
                <option value="basic">Basic</option>
                <option value="pro">Pro</option>
                <option value="enterprise">Enterprise</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Control 
                as="select"
                value={newSubscription.status} 
                onChange={(e) => setNewSubscription({...newSubscription, status: e.target.value})}
                required
              >
                <option value="">Select a status</option>
                <option value="active">Active</option>
                <option value="pending">Pending</option>
                <option value="cancelled">Cancelled</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Start Date</Form.Label>
              <Form.Control 
                type="date" 
                value={newSubscription.startDate} 
                onChange={(e) => setNewSubscription({...newSubscription, startDate: e.target.value})}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Create Subscription
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Subscription;