import React, { useState, useContext, useEffect } from 'react';
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel } from '@mui/material';
import { saveAppointmentSettings, getAppointmentSettings } from '../services/api';
import { OrganizationContext } from '../contexts/OrganizationContext';

const AppointmentSettings = () => {
  const { organizationId } = useContext(OrganizationContext);
  const [settings, setSettings] = useState({
    organizationId: '',
    eventName: '',
    googleCalendarId: '',
    meetingDurationMinutes: 30,
    dayStartTime: '09:00',
    dayEndTime: '17:00',
    timeBetweenMeetingsMinutes: 15,
    googleCredentials: '',
    appointmentBufferHours: 1,
    bookingOpenNoDays: 30,
    weekendDays: [],
    timeZone: 'Europe/London',
  });


  useEffect(() => {
    if (organizationId) {
      // Fetch settings for the current organization when the organizationId is available
      const fetchSettings = async () => {
        try {
          const fetchedSettings = await getAppointmentSettings(organizationId);
          setSettings({
            ...settings,
            ...fetchedSettings,
            organizationId: organizationId || '',
          });
        } catch (error) {
          console.error('Error fetching appointment settings:', error);
        }
      };

      fetchSettings();
    }
  }, [organizationId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: value
    }));
  };

  const handleWeekendDaysChange = (dayIndex) => {
    setSettings((prevSettings) => {
      const weekendDays = prevSettings.weekendDays.includes(dayIndex)
        ? prevSettings.weekendDays.filter((d) => d !== dayIndex)
        : [...prevSettings.weekendDays, dayIndex];
      return { ...prevSettings, weekendDays: weekendDays.filter(day => typeof day === 'number') };
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await saveAppointmentSettings({ ...settings, organizationId });
      alert('Settings saved successfully');
    } catch (error) {
      console.error('Error saving settings:', error);
      alert('Error saving settings');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        name="organizationId"
        label="Organization ID"
        value={settings.organizationId}
        fullWidth
        margin="normal"
      />
      <TextField
        name="eventName"
        label="Event Name"
        value={settings.eventName}
        fullWidth
        margin="normal"
      />
      <TextField
        name="googleCalendarId"
        label="Google Calendar ID"
        value={settings.googleCalendarId}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        name="meetingDurationMinutes"
        label="Meeting Duration (minutes)"
        type="number"
        value={settings.meetingDurationMinutes}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
{/*       <TimePicker
        name="dayStartTime"
        label="Day Start Time"
        value={settings.dayStartTime}
        onChange={(time) => handleChange({ target: { name: 'dayStartTime', value: time } })}
        fullWidth
        margin="normal"
      />
      <TimePicker
        name="dayEndTime"
        label="Day End Time"
        value={settings.dayEndTime}
        onChange={(time) => handleChange({ target: { name: 'dayEndTime', value: time } })}
        fullWidth
        margin="normal"
      /> */}
      <TextField
        name="dayStartTime"
        label="Day Start Time"
        value={settings.dayStartTime}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        name="dayEndTime"
        label="Day End Time"
        value={settings.dayEndTime}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />      
      <TextField
        name="timeBetweenMeetingsMinutes"
        label="Time Between Meetings (minutes)"
        type="number"
        value={settings.timeBetweenMeetingsMinutes}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
{/*       <TextField
        name="googleCredentials"
        label="Google Credentials (JSON)"
        value={settings.googleCredentials}
        onChange={handleChange}
        fullWidth
        multiline
        rows={4}
        margin="normal"
      />
 */}      <TextField
        name="appointmentBufferHours"
        label="Appointment Buffer Hours"
        type="number"
        value={settings.appointmentBufferHours}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        name="bookingOpenNoDays"
        label="Booking Open Number of Days"
        type="number"
        value={settings.bookingOpenNoDays}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>timeZone</InputLabel>
        <Select
          name="timeZone"
          value={settings.timeZone}
          onChange={handleChange}
        >
          <MenuItem value="Europe/London">Europe/London</MenuItem>
          <MenuItem value="America/New_York">America/New_York</MenuItem>
          <MenuItem value="America/Los_Angeles">America/Los_Angeles</MenuItem>
          <MenuItem value="Asia/Tokyo">Asia/Tokyo</MenuItem>
          <MenuItem value="Australia/Sydney">Australia/Sydney</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
      <InputLabel>Weekend Days</InputLabel>
        <div>
          {[0, 1, 2, 3, 4, 5, 6].map((dayIndex) => (
            <FormControlLabel
              key={dayIndex}
              control={
                <Checkbox
                  checked={settings.weekendDays.includes(dayIndex)}
                  onChange={() => handleWeekendDaysChange(dayIndex)}
                />
              }
              label={['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayIndex]}
            />
          ))}
        </div>
      </FormControl>
      <Button type="submit" variant="contained" color="primary">
        Save Settings
      </Button>
    </form>
  );
};

export default AppointmentSettings;