import React, { createContext, useState, useEffect, useCallback } from 'react';
import { getOrganizationByUser, getUser } from '../services/api';

export const OrganizationContext = createContext();

export const OrganizationProvider = ({ children }) => {
    const [organizationId, setOrganizationId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const refreshOrganization = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const email = getUser();
            if (email) {
                const organization = await getOrganizationByUser(email);
                setOrganizationId(organization.id);
            }
        } catch (error) {
            console.error('Error fetching organization:', error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        refreshOrganization();
    }, [refreshOrganization]);

    return (
        <OrganizationContext.Provider value={{ 
            organizationId, 
            setOrganizationId, 
            refreshOrganization,
            isLoading,
            error 
        }}>
            {children}
        </OrganizationContext.Provider>
    );
};