import React, { useEffect, useState, useContext } from 'react';
import { handleAuthorize, getGoogleAuthInfo, revokeGoogleAuth, getOrganizationIdFromAPI } from '../services/api';
import { OrganizationContext } from '../contexts/OrganizationContext';


const GoogleAuthComponent = () => {
    const { organizationId } = useContext(OrganizationContext);
    const [krupaOrganizationId, setKrupaOrganizationId] = useState(null);
    const [authUrl, setAuthUrl] = useState('');
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [associatedEmail, setAssociatedEmail] = useState('');

 
     useEffect(() => {
        // Here, you would typically fetch the organization ID based on the logged-in user
        // For example, from an API call or from local storage
        const fetchOrganizationId = async () => {
            // Replace this with your actual logic to get the organization ID
            const id = await getOrganizationIdFromAPI();
            console.log('krupaOrganizationId'+id);
            setKrupaOrganizationId(id);
        };

        fetchOrganizationId();
    }, []);

    useEffect(() => {
        if (krupaOrganizationId) {
            checkAuthStatus();
        }
    }, [krupaOrganizationId]);
    
    const checkAuthStatus = async () => {
        if (!krupaOrganizationId) return;
        
        try {
            const authInfo = await getGoogleAuthInfo(organizationId);
            console.log('authInfo'+authInfo.isAuthorized);
            setIsAuthorized(authInfo.isAuthorized);
            setAssociatedEmail(authInfo.email);
        } catch (error) {
            console.error('Error checking auth status:', error);
        }
    };

    const handleAuthorizeGoogle = async () => {
        if (!krupaOrganizationId) {
            console.error('Organization ID is not available');
            return;
        }

        try {
            const url = await handleAuthorize(krupaOrganizationId,organizationId);
            setAuthUrl(url);
            console.log("Authorization URL:", url);
            const authWindow = window.open(url, 'Google Authorization', 'width=600,height=600');
            
            // Instead of polling, set up an interval to check auth status
            const checkInterval = setInterval(async () => {
                const authInfo = await getGoogleAuthInfo(organizationId);
                if (authInfo.isAuthorized) {
                    clearInterval(checkInterval);
                    setIsAuthorized(true);
                    setAssociatedEmail(authInfo.email);
                }
            }, 2000); // Check every 2 seconds

            // Clear the interval after 2 minutes to prevent indefinite checking
            setTimeout(() => clearInterval(checkInterval), 120000);
        } catch (error) {
            console.error('Error getting authorization URL:', error);
        }
    };

    const handleUnauthorizeGoogle = async () => {
        if (!krupaOrganizationId) {
            console.error('Organization ID is not available');
            return;
        }

        try {
            await revokeGoogleAuth(krupaOrganizationId);
            setIsAuthorized(false);
            setAssociatedEmail('');
        } catch (error) {
            console.error('Error revoking Google auth:', error);
        }
    };

    if (!krupaOrganizationId) {
        return <div>Loading organization information...</div>;
    }

    return (
        <div>
            <h2>Google Calendar Authorization</h2>
            {isAuthorized ? (
                <>
                    <p>Authorized with Gmail: {associatedEmail}</p>
                    <button onClick={handleUnauthorizeGoogle}>Unauthorize Google Calendar</button>
                </>
            ) : (
                <>
                    <button onClick={handleAuthorizeGoogle}>Authorize Google Calendar</button>
                    {authUrl && (
                        <p>
                            If the authorization window didn't open, please <a href={authUrl} target="_blank" rel="noopener noreferrer">click here</a>.
                        </p>
                    )}
                </>
            )}
        </div>
    );
};

export default GoogleAuthComponent;