// VapiPhoneNumbers.js
import React, { useState, useEffect, useContext } from 'react';
import { Table, Button, Alert, Spinner, Form, Badge } from 'react-bootstrap';
import { getPhoneNumbers, importPhoneNumber, getAssistants } from '../services/api';
import { OrganizationContext } from '../contexts/OrganizationContext';

const VapiPhoneNumbers = () => {
  const [numbers, setNumbers] = useState([]);
  const [assistants, setAssistants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newNumber, setNewNumber] = useState({
    number: '',
    provider: 'twilio',
    assistantId: '',
    twilioAccountSid: '',
    twilioAuthToken: '',
    credentialId: ''
  });

  const { organizationId } = useContext(OrganizationContext);

  useEffect(() => {
    if (organizationId) {
      fetchData();
    }
  }, [organizationId]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [numbersData, assistantsData] = await Promise.all([
        getPhoneNumbers(organizationId),
        getAssistants(organizationId)
      ]);
      setNumbers(numbersData);
      setAssistants(assistantsData);
      console.log('assistantsData='+JSON.stringify(assistantsData));
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const requestData = {
        provider: newNumber.provider,
        number: newNumber.number,
        assistantId: newNumber.assistantId
      };

      if (newNumber.provider === 'twilio') {
        requestData.twilioAccountSid = newNumber.twilioAccountSid;
        requestData.twilioAuthToken = newNumber.twilioAuthToken;
      } else if (newNumber.provider === 'vonage' || newNumber.provider === 'byo-phone-number') {
        requestData.credentialId = newNumber.credentialId;
      }
      console.log('requestData='+JSON.stringify(requestData));
      console.log('newNumber='+JSON.stringify(newNumber));
      await importPhoneNumber(requestData, organizationId);
      fetchData();
      setNewNumber({
        number: '',
        provider: 'twilio',
        assistantId: '',
        twilioAccountSid: '',
        twilioAuthToken: '',
        credentialId: ''
      });
    } catch (error) {
      setError(error.message);
    }
  };

  const renderProviderFields = () => {
    switch (newNumber.provider) {
      case 'twilio':
        return (
          <>
            <Form.Group className="mb-3">
              <Form.Label>Twilio Account SID</Form.Label>
              <Form.Control
                type="text"
                value={newNumber.twilioAccountSid}
                onChange={(e) => setNewNumber({...newNumber, twilioAccountSid: e.target.value})}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Twilio Auth Token</Form.Label>
              <Form.Control
                type="password"
                value={newNumber.twilioAuthToken}
                onChange={(e) => setNewNumber({...newNumber, twilioAuthToken: e.target.value})}
                required
              />
            </Form.Group>
          </>
        );
      case 'vonage':
      case 'byo-phone-number':
        return (
          <Form.Group className="mb-3">
            <Form.Label>Credential ID</Form.Label>
            <Form.Control
              type="text"
              value={newNumber.credentialId}
              onChange={(e) => setNewNumber({...newNumber, credentialId: e.target.value})}
              required
            />
          </Form.Group>
        );
      default:
        return null;
    }
  };

  if (loading) return <Spinner animation="border" />;
  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <div className="container">
      <h2 className="mb-4">Import Phone Number</h2>
      <Form onSubmit={handleSubmit} className="mb-4">
        <Form.Group className="mb-3">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="text"
            value={newNumber.number}
            onChange={(e) => setNewNumber({...newNumber, number: e.target.value})}
            placeholder="Enter phone number (e.g., +1234567890)"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Provider</Form.Label>
          <Form.Select
            value={newNumber.provider}
            onChange={(e) => setNewNumber({...newNumber, provider: e.target.value})}
          >
            <option value="twilio">Twilio</option>
            <option value="vonage">Vonage</option>
            <option value="byo-phone-number">BYO Phone Number</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Assistant</Form.Label>
          <Form.Select
            value={newNumber.assistantId}
            onChange={(e) => setNewNumber({...newNumber, assistantId: e.target.value})}
            required
          >
            <option value="">Select Assistant</option>
            {assistants.map(assistant => (
              <option key={assistant.id} value={assistant.id}>
                {assistant.id}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        {renderProviderFields()}

        <Button type="submit" variant="primary">Import Number</Button>
      </Form>

      <h2 className="mb-3">Imported Phone Numbers</h2>
      {numbers.length === 0 ? (
        <Alert variant="info">No phone numbers found.</Alert>
      ) : (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Phone Number</th>
              <th>Provider</th>
              <th>Assistant</th>
              <th>Status</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {numbers.map(number => (
              <tr key={number.id}>
                <td>{number.phoneNumber}</td>
                <td>{number.provider}</td>
                <td>{number.assistantName}</td>
                <td>
                  <Badge bg={number.status === 'active' ? 'success' : 'warning'}>
                    {number.status}
                  </Badge>
                </td>
                <td>{new Date(number.createdAt).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default VapiPhoneNumbers;