import React, { useState, useEffect } from 'react';
//import axios from 'axios';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { getVAPIAssistants, deleteVAPIAssistant, createVAPIAssistantCall, getLiveVAPIAssistant, updateLiveVAPIAssistant, getLiveVAPIAssistants, deleteLiveVAPIAssistant } from '../services/api';


const AssistantList = () => {
  const [assistants, setAssistants] = useState([]);
  const [liveAssistants, setLiveAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState(null);

  useEffect(() => {
    fetchAssistants();
    fetchLiveAssistants();
  }, []);

  const fetchAssistants = async () => {
    try {
      const data = await getVAPIAssistants();
      setAssistants(data);
    } catch (error) {
      console.error('Failed to fetch assistants:', error);
    }
  };

  const fetchLiveAssistants = async () => {
    try {
        const data = await getLiveVAPIAssistants();
        setLiveAssistants(data);
      } catch (error) {
        console.error('Failed to fetch live assistants:', error);
      }
    };

  const handleDeleteAssistant = async (id) => {
    if (window.confirm('Are you sure you want to delete this assistant?')) {
      try {
        await deleteVAPIAssistant(id);
        setAssistants(assistants.filter(assistant => assistant.id !== id));
      } catch (error) {
        console.error('Failed to delete assistant:', error);
      }
    }
  }; 

  const handleCreateCall = async (id) => {
    try {
      const response = await createVAPIAssistantCall(id);
      if (response.assistantId) {
        alert('Call created successfully');
        fetchLiveAssistants();
      }
    } catch (error) {
      console.error('Failed to create call:', error);
      alert('Failed to create call');
    }
  };

  const handleDeleteLiveAssistant = async (assistantId) => {
    if (window.confirm('Are you sure you want to delete this Live assistant?')) {
        try {
          await deleteLiveVAPIAssistant(assistantId);
          setLiveAssistants(liveAssistants.filter(liveAssistant => liveAssistant.assistantId !== assistantId));
        } catch (error) {
          console.error('Failed to delete live assistant:', error);
        }
      }
  
  };  

  const isAssistantLive = (assistantId) => {
    return liveAssistants.some(liveAssistant => liveAssistant.assistantId === assistantId);
  };

  return (
    <div>
      <h2>VAPI Assistants</h2>
      <Link to="/vapi-assistants/create">
        <Button variant="contained" color="primary">Create New Assistant</Button>
      </Link>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assistants.map((assistant) => (
              <TableRow key={assistant.id}>
                <TableCell>{assistant.name}</TableCell>
                <TableCell>
                  {!isAssistantLive(assistant.id) && (
                    <>
                      <Link to={`vapi-assistants/edit/${assistant.id}`}>
                        <Button variant="contained" color="primary">Edit</Button>
                      </Link>
                      <Button variant="contained" color="secondary" onClick={() => handleDeleteAssistant(assistant.id)}>Delete</Button>
                      <Button variant="contained" color="default" onClick={() => handleCreateCall(assistant.id)}>Create Call</Button>
                    </>
                  )}
                  <Button variant="contained" color="default" onClick={() => setSelectedAssistant(assistant)}>Select</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedAssistant && (
        <div>
          <h2>VAPI Assistants Live</h2>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Assistant ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {liveAssistants
                  .filter(liveAssistant => liveAssistant.vapiAssistant.id === selectedAssistant.id)
                  .map((liveAssistant) => (
                    <TableRow key={liveAssistant.assistantId}>
                      <TableCell>{liveAssistant.assistantId}</TableCell>
                      <TableCell>{liveAssistant.name}</TableCell>
                      <TableCell>
                        <Link to={`/vapi-assistants-live/edit/${liveAssistant.assistantId}`}>
                          <Button variant="contained" color="primary">Edit</Button>
                        </Link>
                        <Button variant="contained" color="secondary" onClick={() => handleDeleteLiveAssistant(liveAssistant.assistantId)}>Delete</Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default AssistantList;