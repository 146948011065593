import React, { useState, useEffect, useContext } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { TextField, Button } from '@mui/material';
import { Table, Pagination, Alert } from 'react-bootstrap';
import { getMonthlyBillings, getCurrentMonthlyBilling, getMonthlyBillingSummary } from '../services/api';

const formatCurrency = (amount) => {
  if (!amount) return '£0.00';
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

const formatDate = (dateString) => {
  if (!dateString) return '';
  return new Date(dateString).toLocaleDateString('en-GB', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

const MonthlyBilling = () => {
  const { organizationId } = useContext(OrganizationContext);    
  const [billings, setBillings] = useState([]);
  const [currentBilling, setCurrentBilling] = useState(null);
  const [billingSummary, setBillingSummary] = useState(null);  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    console.log('organizationId:', organizationId); // Debug log
    if (organizationId) {
      fetchBillings();
      fetchCurrentBilling();
    }
  }, [organizationId, currentPage]);

  const calculateTotalTopUps = (billing) => {
    if (!billing.payments) return 0;
    return billing.payments
      .filter(payment => payment.paymentType === 'top-up')
      .reduce((sum, payment) => sum + (payment.amount || 0), 0);
  };

  const calculateTopUpCount = (billing) => {
    if (!billing.payments) return 0;
    return billing.payments
      .filter(payment => payment.paymentType === 'top-up')
      .length;
  };

  const fetchBillings = async () => {
    setLoading(true);
    try {
      console.log('Fetching billings for page:', currentPage); // Debug log
      const response = await getMonthlyBillings(organizationId, currentPage);
      console.log('Billings response:', response); // Debug log
      if (response && response.content) {
        setBillings(response.content);
        setTotalPages(response.totalPages || 1);
      } else {
        setBillings([]);
        setTotalPages(1);
      }
      setError(null);
    } catch (err) {
      console.error('Error fetching billings:', err);
      setError('Failed to fetch billing information. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchCurrentBilling = async () => {
    try {
      console.log('Fetching current billing'); // Debug log
      const current = await getCurrentMonthlyBilling(organizationId);
      console.log('Current billing response:', current); // Debug log
      setCurrentBilling(current);
    } catch (err) {
      console.error('Error fetching current billing:', err);
    }
  };

  const fetchBillingSummary = async () => {
    if (!startDate || !endDate) return;
    try {
      console.log('Fetching billing summary:', { startDate, endDate }); // Debug log
      const summary = await getMonthlyBillingSummary(organizationId, startDate, endDate);
      console.log('Summary response:', summary); // Debug log
      setBillingSummary(summary);
    } catch (err) {
      console.error('Error fetching billing summary:', err);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber - 1);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate') setStartDate(value);
    if (name === 'endDate') setEndDate(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setCurrentPage(0);
    fetchBillings();
    fetchBillingSummary();
  };

  const renderBillingInfo = () => {
    if (!currentBilling) return null;
    console.log('Rendering billing info:', currentBilling); // Debug log

    const totalTopUps = calculateTotalTopUps(currentBilling);
    const totalAvailable = (currentBilling.coveredAmount || 0) + totalTopUps + (currentBilling.carryOverAmount || 0);

    const payments = currentBilling.payments || [];
  
    const topUpBreakdown = currentBilling.payments
      ?.filter(payment => payment.paymentType === 'top-up')
      .map((payment, index) => (
        <li key={payment.id || index}>
          Top-up {index + 1}: {formatCurrency(payment.amount)} 
          ({payment.paymentDate ? formatDate(payment.paymentDate) : 'Date not available'})
        </li>
      )) || [];
  
    return (
      <Alert variant="info" className="mb-4">
        <div className="d-flex justify-content-between align-items-start mb-2">
          <div>
            <h5>Current Billing Period</h5>
            <div>{formatDate(currentBilling.billingStartDate)} to {formatDate(currentBilling.billingEndDate)}</div>
          </div>
          <div className="text-right">
            <h5>Billing Status</h5>
            <div>
              {formatCurrency(currentBilling.spentAmount)} / {formatCurrency(totalAvailable)}
            </div>
          </div>
        </div>
        <div className="small mt-2">
          <strong>Breakdown:</strong>
          <ul className="list-unstyled mb-0">
            <li>Base Covered Amount: {formatCurrency(currentBilling.coveredAmount)}</li>
            {topUpBreakdown}
            <li>Total Top-ups: {formatCurrency(totalTopUps)}</li>
            <li>Carry Over: {formatCurrency(currentBilling.carryOverAmount)}</li>
          </ul>
        </div>
      </Alert>
    );
  };

  const renderFilters = () => (
    <form onSubmit={handleSubmit} className="mb-4">
      <div className="row">
        <div className="col-md-4">
          <TextField
            label="Start Date"
            type="date"
            name="startDate"
            value={startDate}
            onChange={handleDateChange}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-md-4">
          <TextField
            label="End Date"
            type="date"
            name="endDate"
            value={endDate}
            onChange={handleDateChange}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-md-4 d-flex align-items-center">
          <Button 
            variant="contained" 
            color="primary" 
            type="submit"
            style={{ marginTop: '16px' }}
            fullWidth
          >
            Filter
          </Button>
        </div>
      </div>
    </form>
  );

  console.log('Render state:', { loading, error, billings, currentBilling }); // Debug log

  if (loading && billings.length === 0) {
    return <div className="p-4 text-center">Loading billing information...</div>;
  }

  return (
    <div className="container-fluid py-4">
      <h1 className="mb-4">Monthly Billing</h1>
      
      {error && <Alert variant="danger">{error}</Alert>}
      
      {renderBillingInfo()}
      {renderFilters()}

      {billingSummary && (
        <Alert variant="success" className="mb-4">
          <h5>Summary for Selected Period</h5>
          <div>Total Spent: {formatCurrency(billingSummary.totalSpent)}</div>
          <div>Total Top-Up: {formatCurrency(billingSummary.totalTopUp)}</div>
          <div>Number of Top-Ups: {billingSummary.totalTopUpCount}</div>
        </Alert>
      )}

      {billings.length === 0 ? (
        <Alert variant="info">No billing records available for the selected period.</Alert>
      ) : (
        <>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Billing Period</th>
                <th className="text-right">Covered Amount</th>
                <th className="text-right">Spent Amount</th>
                <th className="text-center">Top-Up Count</th>
                <th className="text-right">Top-Up Total</th>
                <th className="text-right">Carry Over Amount</th>
              </tr>
            </thead>
            <tbody>
              {billings.map((billing) => {
                const totalTopUps = calculateTotalTopUps(billing);
                const topUpCount = calculateTopUpCount(billing);
                return (
                  <tr key={billing.id}>
                    <td>
                      {formatDate(billing.billingStartDate)} to {formatDate(billing.billingEndDate)}
                    </td>
                    <td className="text-right">{formatCurrency(billing.coveredAmount)}</td>
                    <td className="text-right">{formatCurrency(billing.spentAmount)}</td>
                    <td className="text-center">{topUpCount}</td>
                    <td className="text-right">{formatCurrency(totalTopUps)}</td>
                    <td className="text-right">{formatCurrency(billing.carryOverAmount)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          
          <div className="d-flex justify-content-center mt-4">
            <Pagination>
              {[...Array(totalPages).keys()].map((page) => (
                <Pagination.Item
                  key={page + 1}
                  active={page === currentPage}
                  onClick={() => handlePageChange(page + 1)}
                >
                  {page + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        </>
      )}
    </div>
  );
};

export default MonthlyBilling;