import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, Checkbox, FormControlLabel, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { getLiveVAPIAssistant, updateLiveVAPIAssistant } from '../services/api';

const LiveAssistantEdit = () => {
  const [liveAssistant, setLiveAssistant] = useState(null);
  const { assistantId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchLiveAssistant();
  }, [assistantId]);

  const fetchLiveAssistant = async () => {
    try {
      console.log('fetching for assistantId:',assistantId);  
      const data = await getLiveVAPIAssistant(assistantId);
      setLiveAssistant(data);
    } catch (error) {
      console.error('Failed to fetch live assistant:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setLiveAssistant(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleJsonChange = (e) => {
    const { name, value } = e.target;
    try {
      const parsedValue = JSON.parse(value);
      setLiveAssistant(prev => ({
        ...prev,
        [name]: parsedValue
      }));
    } catch (error) {
      console.error(`Invalid JSON for ${name}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateLiveVAPIAssistant(assistantId, liveAssistant);
      navigate('/vapi-assistants');
    } catch (error) {
      console.error('Failed to update live assistant:', error);
    }
  };

  if (!liveAssistant) return <div>Loading...</div>;

  if (!liveAssistant) return <div>Loading... (Assistant ID: {assistantId})</div>;

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        name="name"
        label="Name"
        value={liveAssistant.name}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        name="transcriber"
        label="Transcriber Configuration (JSON)"
        value={JSON.stringify(liveAssistant.transcriber, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="model"
        label="Model Configuration (JSON)"
        value={JSON.stringify(liveAssistant.model, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="voice"
        label="Voice Configuration (JSON)"
        value={JSON.stringify(liveAssistant.voice, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>First Message Mode</InputLabel>
        <Select
          name="firstMessageMode"
          value={liveAssistant.firstMessageMode}
          onChange={handleChange}
        >
          <MenuItem value="assistant-speaks-first">Assistant Speaks First</MenuItem>
          <MenuItem value="user-speaks-first">User Speaks First</MenuItem>
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            checked={liveAssistant.recordingEnabled}
            onChange={handleChange}
            name="recordingEnabled"
          />
        }
        label="Recording Enabled"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={liveAssistant.hipaaEnabled}
            onChange={handleChange}
            name="hipaaEnabled"
          />
        }
        label="HIPAA Enabled"
      />
      <TextField
        name="clientMessages"
        label="Client Messages (JSON array)"
        value={JSON.stringify(liveAssistant.clientMessages, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="serverMessages"
        label="Server Messages (JSON array)"
        value={JSON.stringify(liveAssistant.serverMessages, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="silenceTimeoutSeconds"
        label="Silence Timeout (seconds)"
        type="number"
        value={liveAssistant.silenceTimeoutSeconds}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        name="maxDurationSeconds"
        label="Max Duration (seconds)"
        type="number"
        value={liveAssistant.maxDurationSeconds}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        name="backgroundSound"
        label="Background Sound"
        value={liveAssistant.backgroundSound}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={liveAssistant.backchannelingEnabled}
            onChange={handleChange}
            name="backchannelingEnabled"
          />
        }
        label="Backchanneling Enabled"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={liveAssistant.backgroundDenoisingEnabled}
            onChange={handleChange}
            name="backgroundDenoisingEnabled"
          />
        }
        label="Background Denoising Enabled"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={liveAssistant.modelOutputInMessagesEnabled}
            onChange={handleChange}
            name="modelOutputInMessagesEnabled"
          />
        }
        label="Model Output In Messages Enabled"
      />
      <TextField
        name="transportConfigurations"
        label="Transport Configurations (JSON)"
        value={JSON.stringify(liveAssistant.transportConfigurations, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="firstMessage"
        label="First Message"
        value={liveAssistant.firstMessage}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        name="voicemailDetection"
        label="Voicemail Detection (JSON)"
        value={JSON.stringify(liveAssistant.voicemailDetection, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="voicemailMessage"
        label="Voicemail Message"
        value={liveAssistant.voicemailMessage}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        name="endCallMessage"
        label="End Call Message"
        value={liveAssistant.endCallMessage}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        name="endCallPhrases"
        label="End Call Phrases (JSON array)"
        value={JSON.stringify(liveAssistant.endCallPhrases, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="metadata"
        label="Metadata (JSON)"
        value={JSON.stringify(liveAssistant.metadata, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="serverUrl"
        label="Server URL"
        value={liveAssistant.serverUrl}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        name="analysisPlan"
        label="Analysis Plan (JSON)"
        value={JSON.stringify(liveAssistant.analysisPlan, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="artifactPlan"
        label="Artifact Plan (JSON)"
        value={JSON.stringify(liveAssistant.artifactPlan, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="messagePlan"
        label="Message Plan (JSON)"
        value={JSON.stringify(liveAssistant.messagePlan, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="startSpeakingPlan"
        label="Start Speaking Plan (JSON)"
        value={JSON.stringify(liveAssistant.startSpeakingPlan, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="stopSpeakingPlan"
        label="Stop Speaking Plan (JSON)"
        value={JSON.stringify(liveAssistant.stopSpeakingPlan, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="credentialIds"
        label="Credential IDs (JSON array)"
        value={JSON.stringify(liveAssistant.credentialIds, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
        Update Live Assistant
      </Button>
    </form>
  );
};

export default LiveAssistantEdit;