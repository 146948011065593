// src/components/CallTrack.js
import React, { useEffect, useState, useContext } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { getSubscriptionStartDate, fetchCallRecords } from '../services/api';
import { TextField, MenuItem, Select, InputLabel, FormControl, Button } from '@mui/material';
import { Table, Pagination } from 'react-bootstrap';

const CallTrack = () => {
  const { organizationId } = useContext(OrganizationContext);
  const [callRecords, setCallRecords] = useState([]);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [assistantId, setAssistantId] = useState('');
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchSubscriptionDate = async () => {
      if (organizationId) {
        try {
          const subscriptionDate = await getSubscriptionStartDate(organizationId);
          setSubscriptionStartDate(new Date(subscriptionDate));
        } catch (error) {
          console.error('Error fetching subscription start date:', error);
        }
      }
    };

    fetchSubscriptionDate();
  }, [organizationId]);

  useEffect(() => {
    const fetchRecords = async () => {
      if (organizationId) {
        try {
          const response = await fetchCallRecords(organizationId, { 
            month: month || undefined, 
            year: year || undefined, 
            assistantId, 
            page: currentPage, 
            size: 10
          });
          setCallRecords(response.content || []);
          setTotalPages(response.totalPages || 1);
        } catch (error) {
          console.error('Error fetching call records:', error);
        }
      }
    };

    fetchRecords();
  }, [organizationId, month, year, assistantId, currentPage]);

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
    setCurrentPage(1);
  };

  const handleYearChange = (e) => {
    setYear(e.target.value);
    setCurrentPage(1);
  };

  const handleAssistantIdChange = (e) => {
    setAssistantId(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderFilters = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 2019 + 1 }, (_, i) => 2020 + i);
    const months = [
      { value: '01', label: 'January' },
      { value: '02', label: 'February' },
      { value: '03', label: 'March' },
      { value: '04', label: 'April' },
      { value: '05', label: 'May' },
      { value: '06', label: 'June' },
      { value: '07', label: 'July' },
      { value: '08', label: 'August' },
      { value: '09', label: 'September' },
      { value: '10', label: 'October' },
      { value: '11', label: 'November' },
      { value: '12', label: 'December' },
    ];

    return (
      <div>
        <FormControl fullWidth margin="normal">
          <InputLabel>Assistant ID</InputLabel>
          <TextField value={assistantId} onChange={handleAssistantIdChange} fullWidth />
        </FormControl>
        {subscriptionStartDate && (
          <>
            <FormControl fullWidth margin="normal">
              <InputLabel>Month</InputLabel>
              <Select value={month} onChange={handleMonthChange} fullWidth>
                {months.map((m) => (
                  <MenuItem key={m.value} value={m.value}>
                    {m.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Year</InputLabel>
              <Select value={year} onChange={handleYearChange} fullWidth>
                {years.map((y) => (
                  <MenuItem key={y} value={y}>
                    {y}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      <h1 className="mb-4">Call Track Records</h1>
      {renderFilters()}
      {callRecords && callRecords.length === 0 ? (
        <p>No call records available.</p>
      ) : (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Call ID</th>
                <th>Assistant ID</th>
                <th>Call Duration (minutes)</th>
                <th>Date</th>
                <th>Caller ID</th>
                <th>Cost</th>
              </tr>
            </thead>
            <tbody>
              {callRecords.map((record) => (
                <tr key={record.id}>
                  <td>{record.id}</td>
                  <td>{record.assistantResponse.id}</td>
                  <td>{record.durationInMinutes}</td>
                  <td>{new Date(record.startTime).toLocaleString()}</td>
                  <td>{record.callId}</td>
                  <td style={{ textAlign: 'right' }}>
                        {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        }).format(record.cost)}
                  </td>
              </tr>
              ))}
            </tbody>
          </Table>
          <Pagination className="mt-4">
            {[...Array(totalPages).keys()].map((page) => (
              <Pagination.Item
                key={page + 1}
                active={page + 1 === currentPage}
                onClick={() => handlePageChange(page + 1)}
              >
                {page + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </>
      )}
    </div>
  );
};

export default CallTrack;