import React, { useState, useEffect, useContext } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { createCheckoutSession, getPaymentHistory } from '../services/api';
import '../styles/StripePayment.css';

const PRODUCTS = [
  {
    id: 'basic',
    name: 'Basic Plan',
    priceId: process.env.REACT_APP_BASIC_PRICE_ID,
    description: 'Basic features for small businesses',
    price: '$9.99/month'
  },
  {
    id: 'pro',
    name: 'Pro Plan',
    priceId: process.env.REACT_APP_PRO_PRICE_ID,
    description: 'Advanced features for growing businesses',
    price: '$29.99/month'
  }
];

const SuccessPage = () => {
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { organizationId } = useContext(OrganizationContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        setLoading(true);
        const searchParams = new URLSearchParams(location.search);
        const sessionId = searchParams.get('session_id');
        
        if (!sessionId) {
          throw new Error('No session ID found');
        }

        if (!organizationId) {
          console.log('Waiting for organization ID...');
          return; // Exit early if organizationId is not available yet
        }

        console.log("Fetching payment history for organization:", organizationId);
        const paymentHistory = await getPaymentHistory(organizationId);
        console.log("Payment history received:", paymentHistory);

        if (!paymentHistory || paymentHistory.length === 0) {
          throw new Error('No payment history found');
        }

        const latestPayment = paymentHistory[0];
        console.log("Latest payment:", latestPayment);

        setPaymentDetails({
          sessionId,
          amount: latestPayment.amount,
          currency: latestPayment.currency || 'GBP',
          status: latestPayment.status,
          date: new Date(latestPayment.paymentDate).toLocaleDateString(),
          paymentType: latestPayment.paymentType,
          referenceId: latestPayment.stripePaymentIntentId
        });

        setError(null);
      } catch (err) {
        console.error('Error in fetchPaymentDetails:', err);
        setError('Failed to load payment details. Please contact support.');
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentDetails();
  }, [organizationId, location.search]); // Re-run when organizationId becomes available

  useEffect(() => {
    // Debug logging for context values
    console.log("Organization ID from context:", organizationId);
  }, [organizationId]);

  if (!organizationId) {
    return (
      <div className="payment-result-container">
        <div className="loading">Initializing payment details...</div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="payment-result-container">
        <div className="loading">Loading payment details...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="payment-result-container">
        <div className="error-message">
          <h2>Error Loading Payment Details</h2>
          <p>{error}</p>
          <p className="error-details">Organization ID: {organizationId}</p>
          <button onClick={() => navigate('/')} className="return-button">
            Return to Dashboard
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="payment-result-container">
      <div className="success-message">
        <h2>Payment Successful!</h2>
        {paymentDetails && (
          <div className="payment-details">
            <h3>Payment Details</h3>
            <div className="detail-row">
              <span>Amount:</span>
              <span>{new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: paymentDetails.currency
              }).format(paymentDetails.amount)}</span>
            </div>
            <div className="detail-row">
              <span>Date:</span>
              <span>{paymentDetails.date}</span>
            </div>
            <div className="detail-row">
              <span>Payment Type:</span>
              <span>{paymentDetails.paymentType}</span>
            </div>
            <div className="detail-row">
              <span>Reference ID:</span>
              <span>{paymentDetails.referenceId}</span>
            </div>
            <div className="detail-row">
              <span>Status:</span>
              <span className="status-success">{paymentDetails.status}</span>
            </div>
            <div className="detail-row">
              <span>Organization ID:</span>
              <span>{organizationId}</span>
            </div>
          </div>
        )}
        <p>You will receive a confirmation email shortly.</p>
        <button onClick={() => navigate('/')} className="return-button">
          Return to Dashboard
        </button>
      </div>
    </div>
  );
};

const FailedPage = () => {
  const navigate = useNavigate();

  return (
    <div className="payment-result-container">
      <div className="failed-message">
        <h2>Payment Failed</h2>
        <p>Unfortunately, your payment was not successful.</p>
        <div className="error-details">
          <p>This could be due to:</p>
          <ul>
            <li>Insufficient funds</li>
            <li>Expired card</li>
            <li>Invalid card details</li>
            <li>Bank declined transaction</li>
          </ul>
        </div>
        <p>Please try again or contact your bank for more information.</p>
        <div className="action-buttons">
          <button onClick={() => navigate('/stripe-payment-component')} className="retry-button">
            Try Again
          </button>
          <button onClick={() => navigate('/')} className="return-button">
            Return to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { organizationId } = useContext(OrganizationContext);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements || !selectedProduct) {
      console.log('Missing required elements:', { 
        stripe: !!stripe, 
        elements: !!elements, 
        selectedProduct: !!selectedProduct 
      });
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const { sessionId } = await createCheckoutSession(organizationId, selectedProduct.priceId);
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        setError(error.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="subscription-container">
      <h2>Select a Subscription Plan</h2>
      <div className="products-grid">
        {PRODUCTS.map((product) => (
          <div 
            key={product.id}
            className={`product-card ${selectedProduct?.id === product.id ? 'selected' : ''}`}
            onClick={() => setSelectedProduct(product)}
          >
            <div className="product-details">
              <h3>{product.name}</h3>
              <p>{product.description}</p>
              <p className="price">{product.price}</p>
            </div>
          </div>
        ))}
      </div>

      {error && (
        <div className="error">
          {error}
        </div>
      )}

      {selectedProduct && (
        <form onSubmit={handleSubmit}>
          <button
            type="submit"
            disabled={!stripe || isLoading}
            className="submit-button"
          >
            {isLoading ? 'Processing...' : `Subscribe to ${selectedProduct.name}`}
          </button>
        </form>
      )}
    </div>
  );
};

const StripePaymentComponent = () => {
  const location = useLocation();

  // Determine which component to render based on the current path
  if (location.pathname.includes('/success')) {
    return <SuccessPage />;
  }

  if (location.pathname.includes('/canceled')) {
    return <FailedPage />;
  }
 
  return <PaymentForm />;
};

export default StripePaymentComponent;