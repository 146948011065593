// CheckoutPage.js
import React, { useState, useEffect, useContext} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { createCheckoutSession, getPaymentHistory } from '../services/api';
import { Card, Button, Alert, Container, Row, Col, Spinner } from 'react-bootstrap';

const LoadingSpinner = () => (
    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  );
  
  const SuccessPage = () => {
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { organizationId } = useContext(OrganizationContext);
    const location = useLocation();
    const navigate = useNavigate();
  
    useEffect(() => {
      const fetchPaymentDetails = async () => {
        try {
          setLoading(true);
          const searchParams = new URLSearchParams(location.search);
          const sessionId = searchParams.get('session_id');
          
          if (!sessionId) {
            throw new Error('No session ID found');
          }
  
          if (!organizationId) {
            console.log('Waiting for organization ID...');
            return;
          }
  
          const paymentHistory = await getPaymentHistory(organizationId);
  
          if (!paymentHistory || paymentHistory.length === 0) {
            throw new Error('No payment history found');
          }
  
          const latestPayment = paymentHistory[0];
  
          setPaymentDetails({
            sessionId,
            amount: latestPayment.amount,
            currency: latestPayment.currency || 'GBP',
            status: latestPayment.status,
            date: new Date(latestPayment.paymentDate).toLocaleDateString(),
            paymentType: latestPayment.paymentType,
            referenceId: latestPayment.stripePaymentIntentId
          });
  
          setError(null);
        } catch (err) {
          console.error('Error in fetchPaymentDetails:', err);
          setError('Failed to load payment details. Please contact support.');
        } finally {
          setLoading(false);
        }
      };
  
      fetchPaymentDetails();
    }, [organizationId, location.search]);
  
    if (!organizationId || loading) {
      return (
        <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow">
          <div className="text-center">
            <LoadingSpinner />
            <p className="mt-4 text-gray-600">
              {!organizationId ? 'Initializing payment details...' : 'Loading payment details...'}
            </p>
          </div>
        </div>
      );
    }
  
    if (error) {
      return (
        <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow">
          <div className="text-center">
            <div className="text-red-500 mb-4">⚠️</div>
            <h2 className="text-xl font-semibold mb-2">Error Loading Payment Details</h2>
            <p className="text-red-600 mb-4">{error}</p>
            <p className="text-sm text-gray-600 mb-4">Organization ID: {organizationId}</p>
            <button
              onClick={() => navigate('/')}
              className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Return to Dashboard
            </button>
          </div>
        </div>
      );
    }
  
    return (
      <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow">
        <div className="text-center">
          <div className="text-green-500 text-4xl mb-4">✓</div>
          <h2 className="text-xl font-semibold mb-4">Payment Successful!</h2>
          {paymentDetails && (
            <div className="space-y-3 text-left mb-6">
              <h3 className="text-lg font-medium">Payment Details</h3>
              <div className="grid grid-cols-2 gap-2 text-sm">
                <span className="text-gray-600">Amount:</span>
                <span>{new Intl.NumberFormat('en-GB', {
                  style: 'currency',
                  currency: paymentDetails.currency
                }).format(paymentDetails.amount)}</span>
                
                <span className="text-gray-600">Date:</span>
                <span>{paymentDetails.date}</span>
                
                <span className="text-gray-600">Payment Type:</span>
                <span>{paymentDetails.paymentType}</span>
                
                <span className="text-gray-600">Reference ID:</span>
                <span className="break-all">{paymentDetails.referenceId}</span>
                
                <span className="text-gray-600">Status:</span>
                <span className="text-green-600">{paymentDetails.status}</span>
                
                <span className="text-gray-600">Organization ID:</span>
                <span>{organizationId}</span>
              </div>
            </div>
          )}
          <p className="text-gray-600 mb-6">You will receive a confirmation email shortly.</p>
          <button
            onClick={() => navigate('/')}
            className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Return to Dashboard
          </button>
        </div>
      </div>
    );
  };
  
  const FailedPage = () => {
    const navigate = useNavigate();
  
    return (
      <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow">
        <div className="text-center">
          <div className="text-red-500 text-4xl mb-4">×</div>
          <h2 className="text-xl font-semibold mb-4">Payment Failed</h2>
          <p className="text-gray-600 mb-4">Unfortunately, your payment was not successful.</p>
          <div className="text-left mb-6">
            <p className="font-medium mb-2">This could be due to:</p>
            <ul className="list-disc list-inside text-gray-600 space-y-1">
              <li>Insufficient funds</li>
              <li>Expired card</li>
              <li>Invalid card details</li>
              <li>Bank declined transaction</li>
            </ul>
          </div>
          <p className="text-gray-600 mb-6">Please try again or contact your bank for more information.</p>
          <div className="space-y-3">
            <button
              onClick={() => navigate('/checkout')}
              className="w-full px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Try Again
            </button>
            <button
              onClick={() => navigate('/')}
              className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Return to Dashboard
            </button>
          </div>
        </div>
      </div>
    );
  };
  
const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const { organizationId, isLoading: isOrgLoading, error: orgError } = useContext(OrganizationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {
    const planData = localStorage.getItem('selectedPlan');
    if (planData) {
      setSelectedPlan(JSON.parse(planData));
    }
    console.log("planData="+JSON.stringify(planData));
  }, []);

  const handleCheckout = async (event) => {
    event.preventDefault();
    if (!stripe || !elements || !selectedPlan || !organizationId) {
        console.log('Missing required elements:', { 
          stripe: !!stripe, 
          elements: !!elements, 
          selectedPlan: !!selectedPlan,
          organizationId: !!organizationId
        });
        return;
    }

    setIsLoading(true);
    setError(null);

    try {
      // First save the subscription
      //await saveSubscriptionFromPlan(organizationId);

      // Then create checkout session
      const { sessionId } = await createCheckoutSession(
        organizationId, 
        selectedPlan.priceId
      );

      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        throw new Error(error.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isOrgLoading) {
    return (
      <Container className="mt-5 text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (orgError) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">
          Error loading organization details. Please try again later.
        </Alert>
      </Container>
    );
  }

  if (!organizationId) {
    return (
      <Container className="mt-5">
        <Alert variant="warning">
          Organization not found. Please complete organization setup first.
          <div className="mt-3">
            <Button onClick={() => navigate('/organization')}>
              Setup Organization
            </Button>
          </div>
        </Alert>
      </Container>
    );
  }
  
  if (!selectedPlan) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">No plan selected. Please select a plan first.</Alert>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card>
            <Card.Header as="h4">Checkout Summary</Card.Header>
            <Card.Body>
              <div className="mb-4">
                <h5>Selected Plan Details</h5>
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Plan Name:</td>
                      <td>{selectedPlan.type.charAt(0).toUpperCase() + selectedPlan.type.slice(1)} Plan</td>
                    </tr>
                    <tr>
                      <td>Billing Period:</td>
                      <td>{selectedPlan.billing.charAt(0).toUpperCase() + selectedPlan.billing.slice(1)}</td>
                    </tr>
                    <tr>
                      <td>Amount:</td>
                      <td>${selectedPlan.subscriptionAmount}</td>
                    </tr>
                    <tr>
                      <td>Start Date:</td>
                      <td>{new Date(selectedPlan.startDate).toLocaleDateString()}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {error && (
                <Alert variant="danger" className="mb-4">
                  {error}
                </Alert>
              )}

              <div className="d-grid gap-2">
                <Button 
                  variant="primary" 
                  size="lg"
                  onClick={handleCheckout}
                  disabled={isLoading || !stripe}
                >
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      Processing...
                    </>
                  ) : (
                    'Proceed to Payment'
                  )}
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const CheckoutPage = () => {
    const location = useLocation();
  
    if (location.pathname.includes('/success')) {
      return <SuccessPage />;
    }
  
    if (location.pathname.includes('/canceled')) {
      return <FailedPage />;
    }
  
    return <PaymentForm />;
  };

  export default CheckoutPage;
