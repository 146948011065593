// src/components/Layout.js
import React, { useState, useEffect } from 'react';
//import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container, Row, Col, Button  } from 'react-bootstrap';
import { removeUser, removeAuthToken, checkTokenExpiration } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import { OrganizationProvider } from '../contexts/OrganizationContext';


/* const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: ${props => props.theme.surface};
  height: 60px;
`;

const Logo = styled.img`
  height: 40px;
`;

const UserIcon = styled.div`
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const SideBar = styled.div`
  width: ${props => (props.isExpanded ? '250px' : '60px')};
  background-color: ${props => props.theme.surface};
  transition: width 0.3s ease;
  overflow: hidden;
`;

const MenuItem = styled(Link)`
  display: flex;
  align-items: center;
  padding: 10px;
  color: ${props => props.theme.text};
  text-decoration: none;
  white-space: nowrap;
  
  &:hover {
    background-color: ${props => props.theme.primary};
  }
`;

const MenuIcon = styled.span`
  margin-right: 10px;
  font-size: 20px;
`;

const Content = styled.div`
  flex: 1;
  padding: 20px;
  background-color: ${props => props.theme.background};
  overflow-y: auto;
`;

const ToggleButton = styled.button`
  position: absolute;
  left: ${props => (props.isExpanded ? '250px' : '60px')};
  top: 70px;
  background-color: ${props => props.theme.primary};
  border: none;
  color: ${props => props.theme.text};
  padding: 10px;
  cursor: pointer;
  transition: left 0.3s ease;
`;
 */
const Layout = ({ children, isAdmin }) => {
  const navigate = useNavigate();  
  //const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    const checkAuth = () => {
      if (!checkTokenExpiration()) {
        handleLogout();
      }
    };

    // Check on component mount
    checkAuth();

    // Set up interval to check periodically
    const interval = setInterval(checkAuth, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [navigate]);

  const handleLogout = () => {
    removeAuthToken();
    removeUser();
    navigate('/login');
  };


  return (

//<div className="app-container">
//      <header>
//        <h1>Your App Title</h1>
//        <button onClick={handleLogout}>Logout</button>
//      </header>
//      <div className="main-content">
//{
/*         <nav>
          <ul>
            <li><Link to="/dashboard">Dashboard</Link></li>
            <li><Link to="/vapi-configurations">VAPI Configurations</Link></li>
          </ul>
        </nav>
 */
//}        <main>{children}</main>
//      </div>
//    </div>
//<OrganizationProvider>
     <div className="d-flex flex-column min-vh-100">
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container fluid>
        <Navbar.Brand as={Link} to="./../krupa.jpg">Krupa Consulting</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/profile">👤 Profile</Nav.Link>
            <Button variant="outline-light" onClick={handleLogout}>Logout</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

    <Container fluid className="flex-grow-1">
      <Row className="h-100">
        <Col md={3} lg={2} className="bg-light p-0">
          <Nav className="flex-column">
            <Nav.Link as={Link} to="/">Dashboard</Nav.Link>
            <Nav.Link as={Link} to="/organization">Organization</Nav.Link>            
            <Nav.Link as={Link} to="/call-summary">Call Summary</Nav.Link>
            <Nav.Link as={Link} to="/call-track">Call Tracking</Nav.Link>
            <Nav.Link as={Link} to="/monthly-billing">Monthly Billing</Nav.Link>
            <Nav.Link as={Link} to="/stripe-payment-component">Payment</Nav.Link>
            <Nav.Link as={Link} to="/payment-history-component">Payment History</Nav.Link>
            <Nav.Link as={Link} to="/vapi-phone-numbers">Phone Numbers</Nav.Link>
            <Nav.Link as={Link} to="/upload-files">Upload Files</Nav.Link>
            <Nav.Link as={Link} to="/subscriptions">Subscriptions</Nav.Link>
{/*             <Nav.Link as={Link} to="/appointment-setter">AppointmentSetter</Nav.Link>
            <Nav.Link as={Link} to="/crm-integration">CRM Integration</Nav.Link>
            <Nav.Link as={Link} to="/appointment-setter-v1">AppointmentSetterOld</Nav.Link>
            <Nav.Link as={Link} to="/cancel-appointment">CancelAppointment</Nav.Link>
            <Nav.Link as={Link} to="/reschedule-appointment">RescheduleAppointment</Nav.Link>
 */}            <Nav.Link as={Link} to="/appointments">AppointmentSettings</Nav.Link>
            <Nav.Link as={Link} to="/vapi-assistants">AssistantList</Nav.Link>
            <Nav.Link as={Link} to="/vapi-inbound-calls">Inbound Calls</Nav.Link>
            <Nav.Link as={Link} to="/vapi-outbound-calls">Outbound Dial</Nav.Link>
            <Nav.Link as={Link} to="/google-auth">GoogleAuthComponent</Nav.Link>
            {isAdmin && <Nav.Link as={Link} to="/user-management">User Management</Nav.Link>}
          </Nav>
        </Col>
        <Col md={9} lg={10} className="p-4">
          {children}
        </Col>
      </Row>
    </Container>
  </div>
  //</OrganizationProvider>
 );
};

export default Layout;