// OutboundDial.js 
import React, { useState, useEffect, useContext } from 'react';
import { Form, Button, Alert, Spinner, Table, Badge } from 'react-bootstrap';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { createOutboundCall, getAssistants, getPhoneNumbers } from '../services/api';

const OutboundDial = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [assistantsData, setAssistantsData] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [callData, setCallData] = useState({
    assistantId: '',
    phoneNumberId: '',
    customer: {
      number: '',
      name: '',
      numberE164CheckEnabled: false
    }
  });

  const { organizationId } = useContext(OrganizationContext);

  useEffect(() => {
    if (organizationId) {
      fetchInitialData();
    }
  }, [organizationId]);

  const fetchInitialData = async () => {
    try {
      const [assistantsData, numbersData] = await Promise.all([
        getAssistants(organizationId),
        getPhoneNumbers(organizationId)
      ]);
      console.log(JSON.stringify(numbersData));
      setAssistantsData(assistantsData);
      setPhoneNumbers(numbersData);
    } catch (err) {
      setError('Failed to load initial data: ' + err.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await createOutboundCall({
        assistantId: callData.assistantId,
        phoneNumberId: callData.phoneNumberId,  // Changed from callData.fromNumber
        customer: {
          number: callData.customer.number,
          name: callData.customer.name,
          numberE164CheckEnabled: false
        }
      }, organizationId);

      setSuccess(true);
      setCallData({
        assistantId: '',
        phoneNumberId: '',
        customer: {
          number: '',
          name: '',
          numberE164CheckEnabled: false
        }
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Handle nested customer object properties
    if (name.startsWith('customer.')) {
      const customerField = name.split('.')[1];
      setCallData(prev => ({
        ...prev,
        customer: {
          ...prev.customer,
          [customerField]: value
        }
      }));
    } else {
      // Handle top-level properties
      setCallData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const validatePhoneNumber = (number) => {
    // Basic phone number validation
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    return phoneRegex.test(number);
  };

  const isFormValid = () => {
    const valid = (
      callData.assistantId &&
      callData.phoneNumberId &&
      validatePhoneNumber(callData.customer.number) &&
      callData.customer.name
    );
    
    console.log('Form validation:', {
      assistantId: callData.assistantId,
      phoneNumberId: callData.phoneNumberId,
      customerNumber: callData.customer.number,
      customerName: callData.customer.name,
      isValid: valid
    });
    
    return valid;
  };

  return (
    <div className="container">
      <h2 className="mb-4">Make Outbound Call</h2>

      <Form onSubmit={handleSubmit} className="mb-4">
        <Form.Group className="mb-3">
          <Form.Label>Assistant</Form.Label>
          <Form.Select
            name="assistantId"
            value={callData.assistantId}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Assistant</option>
            {assistantsData.map(assistant => (
              <option key={assistant.id} value={assistant.id}>
                {assistant.id}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>From Number</Form.Label>
          <Form.Select
            name="phoneNumberId"
            value={callData.phoneNumberId}
            onChange={handleInputChange}
            required
          >
            <option value="">Select From Number</option>
            {phoneNumbers.map(number => (
              <option key={number.id} value={number.id}>
                {number.id}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Customer Name</Form.Label>
          <Form.Control
            type="text"
            name="customer.name"
            value={callData.customer.name}
            onChange={handleInputChange}
            placeholder="Enter customer name"
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>To Phone Number</Form.Label>
          <Form.Control
            type="tel"
            name="customer.number"  // Changed from phoneNumber
            value={callData.customer.number}  // Changed from callData.phoneNumber
            onChange={handleInputChange}
            placeholder="Enter phone number (e.g., +1234567890)"
            required
            isInvalid={callData.customer.number && !validatePhoneNumber(callData.customer.number)}
          />
        </Form.Group>

        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">Call initiated successfully!</Alert>}

        <Button 
          type="submit" 
          variant="primary"
          disabled={loading || !isFormValid()}
        >
          {loading ? (
            <>
              <Spinner animation="border" size="sm" className="me-2" />
              Initiating Call...
            </>
          ) : (
            'Make Call'
          )}
        </Button>
      </Form>
    </div>
  );
};

export default OutboundDial;