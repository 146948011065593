import React, { useState, useEffect } from 'react';
import { Table, Button, Alert } from 'react-bootstrap';
import { getCRMIntegrations, deleteCRMIntegration } from '../services/api';

const CRMIntegration = () => {
  const [integrations, setIntegrations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchIntegrations();
  }, []);

  const fetchIntegrations = async () => {
    try {
      setLoading(true);
      const fetchedIntegrations = await getCRMIntegrations();
      setIntegrations(fetchedIntegrations);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch CRM integrations:', error);
      setError('Failed to fetch CRM integrations. Please try again later.');
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this CRM integration?')) {
      try {
        await deleteCRMIntegration(id);
        fetchIntegrations();
      } catch (error) {
        console.error('Failed to delete CRM integration:', error);
        setError('Failed to delete CRM integration. Please try again.');
      }
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <div>
      <h1 className="mb-4">CRM Integration Settings</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>CRM Name</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {integrations.map(integration => (
            <tr key={integration.id}>
              <td>{integration.name}</td>
              <td>{integration.status}</td>
              <td>
                <Button variant="danger" size="sm" onClick={() => handleDelete(integration.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default CRMIntegration;