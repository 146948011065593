import React from 'react';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { format, isValid } from 'date-fns';

const ThankYou = ({ type, appointment }) => {
  const getTitle = () => {
    switch (type) {
      case 'booked':
        return 'Appointment Booked Successfully!';
      case 'rescheduled':
        return 'Appointment Rescheduled Successfully!';
      case 'cancelled':
        return 'Appointment Cancelled Successfully';
      default:
        return 'Thank You';
    }
  };

  const formatDateTime = (dateTimeString) => {
    try {
      const date = new Date(dateTimeString);
      if (!isValid(date)) {
        return 'Invalid Date';
      }
      return format(date, 'PPpp');
    } catch (error) {
      console.error('Date formatting error:', error);
      return 'Invalid Date';
    }
  };

  const renderAppointmentDetails = () => {
    if (!appointment) return null;

    const dateTimeFormatted = formatDateTime(appointment.appointmentDateTime);
    
    return (
      <Row className="g-3">
        <Col xs={12}>
          <Card className="h-100">
            <Card.Header>
              <h5 className="mb-0">Appointment Details</h5>
            </Card.Header>
            <Card.Body>
              <Row className="mb-2">
                <Col xs={12} sm={4} className="text-muted">Date and Time:</Col>
                <Col xs={12} sm={8}>{dateTimeFormatted}</Col>
              </Row>
              {appointment.contact && (
                <>
                  <Row className="mb-2">
                    <Col xs={12} sm={4} className="text-muted">Name:</Col>
                    <Col xs={12} sm={8}>{appointment.contact.name || 'N/A'}</Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={12} sm={4} className="text-muted">Email:</Col>
                    <Col xs={12} sm={8}>{appointment.contact.email || 'N/A'}</Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={12} sm={4} className="text-muted">Phone:</Col>
                    <Col xs={12} sm={8}>{appointment.contact.phone || 'N/A'}</Col>
                  </Row>
                </>
              )}
              <Row className="mb-2">
                <Col xs={12} sm={4} className="text-muted">Timezone:</Col>
                <Col xs={12} sm={8}>{appointment.timezone || 'N/A'}</Col>
              </Row>
              {appointment.message && (
                <Row className="mb-2">
                  <Col xs={12} sm={4} className="text-muted">Message:</Col>
                  <Col xs={12} sm={8}>{appointment.message}</Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  };

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col xs={12} lg={8}>
          <Card>
            <Card.Header className="text-center bg-success text-white">
              <h2 className="h4 mb-0">{getTitle()}</h2>
            </Card.Header>
            <Card.Body>
              {renderAppointmentDetails()}
              
              {type === 'cancelled' && (
                <div className="text-center mt-4">
                  <p>Would you like to schedule a new appointment?</p>
                  <Link to="/appointment-setter">
                    <Button variant="primary">Book New Appointment</Button>
                  </Link>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ThankYou;