import React, { useState, useEffect } from 'react';
import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { getOrganizationByUser, saveOrganization, getUser, saveSubscriptionFromPlan } from '../services/api';
import { useNavigate } from 'react-router-dom';

const TOP_UP_AMOUNTS = [
  { value: 50, label: '£50' },
  { value: 100, label: '£100' },
  { value: 200, label: '£200' },
  { value: 300, label: '£300' }
];

const Organization = ({ history }) => {
    const [organization, setOrganization] = useState({
      id:'',  
      name: '',
      logo: '',
      briefInformation: '',
      defaultTopUpAmount: 100,
      user: [],
    });
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
      // Fetch the organization data after successful login
      const fetchOrganization = async () => {
        try {
            const email = getUser();
            console.log("email"+email);
            const fetchedOrganization = await getOrganizationByUser(email);
            // Check if each field is null and set to empty string if it is
            setOrganization({
              ...fetchedOrganization,
              name: fetchedOrganization.name ?? '',
              logo: fetchedOrganization.logo ?? '',
              briefInformation: fetchedOrganization.briefInformation ?? '',
              defaultTopUpAmount: fetchedOrganization.defaultTopUpAmount ?? 100,
            });
        } catch (error) {
          console.error('Organization not found, redirecting to create page');
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchOrganization();
    }, []);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setOrganization((prev) => ({
        ...prev,
        [name]: value,
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const orgSaved = await saveOrganization(organization);
        console.log('orgSaved='+JSON.stringify(orgSaved));
        await saveSubscriptionFromPlan(orgSaved);
        
        //history.push('/dashboard'); // Redirect to dashboard after saving
        navigate('/checkout');
      } catch (error) {
        console.error('Error saving organization:', error);
      }
    };
  
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    return (
      <>
      <h1 className="mb-4">Organization</h1>
      <form onSubmit={handleSubmit}>
        <TextField  id="id" name="id" label="id" value={organization.id}  margin="normal"/>
        <TextField
          name="name"
          label="Organization Name"
          value={organization.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="logo"
          label="Organization Logo (URL)"
          value={organization.logo}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          name="email"
          label="Organization email"
          value={organization.email}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          name="briefInformation"
          label="Brief Information"
          value={organization.briefInformation}
          onChange={handleChange}
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="default-top-up-label">Default Top-up Amount</InputLabel>
          <Select
            labelId="default-top-up-label"
            name="defaultTopUpAmount"
            value={organization.defaultTopUpAmount}
            onChange={handleChange}
            label="Default Top-up Amount"
          >
            {TOP_UP_AMOUNTS.map((amount) => (
              <MenuItem key={amount.value} value={amount.value}>
                {amount.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button type="submit" variant="contained" color="primary">
          Save Organization
        </Button>
      </form>
     </> 
    );
  };
  
export default Organization;