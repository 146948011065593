import React, { useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Alert, Spinner } from 'react-bootstrap';
import {PRODUCTS} from '../services/api';
import './assets/css/lib/all.min.css';
import './assets/css/lib/animate.css';
import './assets/css/lib/jquery.fancybox.css';
import './assets/css/lib/lity.css';
import './assets/css/lib/swiper.min.css';
import './assets/css/style.css';
// Import images
import heartIcon from './assets/img/icons/nav_icon/imoj_heart.png';
import priceIcon from './assets/img/icons/price_s5.png';



const PricingSection = () => {
  const [billingPeriod, setBillingPeriod] = useState('monthly');
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  
  const renderPricingPlans = () => {
    // Group products by plan type (basic, premium, ultimate)
    const planTypes = ['basic', 'premium', 'ultimate'];
    
    return planTypes.map((planType, index) => {
      const monthlyPlan = PRODUCTS.find(
        product => product.id === planType && product.billingPeriod === 'monthly'
      );
      const yearlyPlan = PRODUCTS.find(
        product => product.id === planType && product.billingPeriod === 'yearly'
      );
  
      return (
        <div 
          key={planType} 
          className={`price-headItem ${planType === 'premium' ? 'bg-gray5' : ''}`}
        >
          <h6>{planType}</h6>
          <h3 className={billingPeriod === 'monthly' ? 'monthly_price' : 'd-none'}>
            {monthlyPlan.price}
          </h3>
          <h3 className={billingPeriod === 'yearly' ? 'yearly_price' : 'd-none'}>
            {yearlyPlan.price}
          </h3>
          <small>Basic Plan!</small>
          <small>Ideal To Start</small>
          {planType === 'premium' && <label>best choice</label>}
        </div>
      );
    });
  };
    
  // And update the price-body section to map features:
  const features = [
    {
      title: 'Unlimited Projects',
      values: { basic: true, premium: true, ultimate: true }
    },
    {
      title: 'Lifetime Support & SEO Experts Consult',
      values: { basic: true, premium: true, ultimate: true }
    },
    {
      title: 'Data Security & Backup',
      values: { basic: '200 GB', premium: '1 TB', ultimate: 'Unlimited' }
    }
    // Add more features as needed
  ];
  
  const renderFeatures = () => {
    return features.map((feature, index) => (
      <div className="price-bodyItems" key={index}>
        <div className="price-bodyTitle">{feature.title}</div>
        {Object.entries(feature.values).map(([plan, value]) => (
          <div 
            key={plan} 
            className={`price-item ${plan === 'premium' ? 'bg-gray5' : ''}`}
          >
            {typeof value === 'boolean' ? 
              value ? <i className="bi bi-check2"></i> : null 
              : value
            }
          </div>
        ))}
      </div>
    ));
  };
  
// And update the footer section:
const renderFooter = () => {
    const planTypes = ['basic', 'premium', 'ultimate'];
    
    return planTypes.map((planType, index) => (
      <div 
        key={planType} 
        className={`price-footItem ${planType === 'premium' ? 'bg-gray5' : ''}`}
      >
        <button 
          onClick={() => handleGetStarted(planType)}
          className="btn rounded-pill blue5-3Dbutn hover-blue2 sm-butn fw-bold"
        >
          Get Started Now
        </button>
      </div>
    ));
  };

  const handleGetStarted = async (planType) => {
    try {
      setIsProcessing(true);
      setError('');
      
      // Find the matching product based on planType and billing period
    const selectedProduct = PRODUCTS.find(
        product => product.id === planType && product.billingPeriod === billingPeriod
      );
  
      if (!selectedProduct) {
        throw new Error('Selected plan configuration not found');
      }
      
      // Store selected plan details with priceId
      const planDetails = {
        type: planType,
        billing: billingPeriod,
        price: getPlanPrice(planType, billingPeriod),
        subscriptionPlan: planType.toUpperCase(),
        subscriptionFreq: billingPeriod === 'monthly' ? 'MONTHLY' : 'YEARLY',
        subscriptionAmount: getPlanPrice(planType, billingPeriod),
        priceId: selectedProduct.priceId, // Adding Stripe price ID
        description: selectedProduct.description,
        productName: selectedProduct.name,
        isActive: true,
        startDate: new Date().toISOString().split('T')[0]
      };
      
      localStorage.setItem('selectedPlan', JSON.stringify(planDetails));
      navigate('/register');
    } catch (error) {
      setError('Failed to process your request. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const getPlanPrice = (planType, billing) => {
    const prices = {
      basic: { monthly: '509.99', yearly: '5700.99' },
      premium: { monthly: '1099.99', yearly: '11400.99' },
      ultimate: { monthly: '1599.99', yearly: '17100.99' }
    };
    return prices[planType][billing];
  };
  
  return (
    <>
      {/* Top Navbar */}
      <div className="top-navbar style-5">
        <div className="container">
          <div className="content text-white">
            <span className="btn sm-butn bg-white py-0 px-2 me-2 fs-10px">
              <span className="small">Special</span>
            </span>
            <img src={heartIcon} className="icon-15" alt="" />
            <span className="fs-10px op-6">Get </span>
            <span className="small">20% Discount</span>
            <span className="fs-10px op-6">Get for New Account</span>
            <Link to="#" className="fs-10px text-decoration-underline ms-2">
              Register Now
            </Link>
          </div>
        </div>
      </div>

      {/* Main Pricing Section */}
      <section className="pricing section-padding border-bottom border-1 brd-gray style-5">
        <div className="container">
          <div className="section-head text-center mb-60 style-5">
            <h2 className="mb-20">Choose Plan and Kick-start</h2>
            <p>Whether you're just getting started with rapid testing or scaling across the organization, we've got you covered.</p>
          </div>

          {/* Pricing Tabs */}
          <div className="pricing-tabsHead text-center">
            <div className="price-radios">
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  name="pricing"
                  id="monthly"
                  className="form-check-input"
                  checked={billingPeriod === 'monthly'}
                  onChange={() => setBillingPeriod('monthly')}
                />
                <label className="form-check-label" htmlFor="monthly">
                  Billed Monthly
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  name="pricing"
                  id="yearly"
                  className="form-check-input"
                  checked={billingPeriod === 'yearly'}
                  onChange={() => setBillingPeriod('yearly')}
                />
                <label className="form-check-label" htmlFor="yearly">
                  Billed yearly <span className="small">-15%</span>
                </label>
              </div>
            </div>
          </div>

          {/* Pricing Table */}
          <div className="table-responsive">
            <div className="content">
            <div className="price-head">
            <div className="price-headTitle">
                <img src={priceIcon} alt="" />
            </div>
                {renderPricingPlans()}
            </div>

              {/* Price Body */}
              <div className="price-body">
                {renderFeatures()}
              </div>

              {/* Price Footer */}
                <div className="price-foot">
                <div className="price-footTitle"></div>
                {renderFooter()}
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PricingSection;