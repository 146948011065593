// src/components/AssistantForm.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, Checkbox, FormControlLabel, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { getVAPIAssistant, createVAPIAssistant, updateVAPIAssistant } from '../services/api';


const AssistantForm = () => {
    const [assistant, setAssistant] = useState({
      name: '',
      transcriber: {
        provider: 'deepgram',
        model: 'nova-2'
      },
      model: {
        provider: 'openai',
        model: 'gpt-4',
        messages: [
          {
            role: 'system',
            content: "You're an AI assistant. How can I help you today?"
          }
        ],
        toolIds: ['66dd97bf-2386-4467-aa1b-68c3cefdee6c','7bac242a-dd7f-49f6-9ea5-2368faf6ea6f','107a01c9-70d9-4a86-abd2-1702a7c10e6f'],
        tools: [            
            {
              async: 'false', 
              type: 'function', 
              name: 'check_availability',
              description: 'Check availability for an appointment',
              parameters: {
                organizationId: 'string',
                preferredDateTime: 'string'
              }
            },
            {
              async: 'false',  
              type: 'function',  
              name: 'book_appointment',
              description: 'Book an appointment',
              parameters: {
                organizationId: 'string',
                appointmentDateTime: 'string',
                customerEmail: 'string'
              }
            },
            {
              async: 'false',  
              type: 'function',  
              name: 'convert_spoken_datetime',
              description: 'Convert a spoken date and time to ISO 8601 format',
              parameters: {
                spokenDateTime: 'string'
              }
            }  
          ]        
      },
      voice: {
        provider: '11labs',
        voiceId: 'marissa'
      },
      firstMessageMode: 'assistant-speaks-first',
      recordingEnabled: true,
      hipaaEnabled: false,
      clientMessages: ['conversation-update', 'function-call', 'hang', 'model-output', 'speech-update', 'status-update', 'transcript', 'tool-calls', 'user-interrupted', 'voice-input'],
      serverMessages: ['conversation-update', 'end-of-call-report', 'function-call', 'hang', 'speech-update', 'status-update', 'tool-calls', 'transfer-destination-request', 'user-interrupted'],
      silenceTimeoutSeconds: 30,
      maxDurationSeconds: 600,
      backgroundSound: 'off',
      backchannelingEnabled: false,
      backgroundDenoisingEnabled: false,
      modelOutputInMessagesEnabled: false,
      transportConfigurations: [{ provider: 'twilio', timeout: 60, record: false, recordingChannels: 'mono' }],
      firstMessage: '',
      voicemailDetection: { provider: 'twilio', enabled: false },
      voicemailMessage: '',
      endCallMessage: '',
      endCallPhrases: [],
      metadata: {},
      serverUrl: 'https://distributewithease.com:8080/api/vapi-webhook',
      serverUrlSecret: '',
      analysisPlan: {},
      artifactPlan: {},
      messagePlan: {},
      startSpeakingPlan: {},
      stopSpeakingPlan: {},
      credentialIds: []
    });

    const { id } = useParams();
  const navigate = useNavigate();


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setAssistant(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleJsonChange = (e) => {
    const { name, value } = e.target;
    try {
      const parsedValue = JSON.parse(value);
      setAssistant(prev => ({
        ...prev,
        [name]: parsedValue
      }));
    } catch (error) {
      console.error(`Invalid JSON for ${name}`);
    }
  };

  useEffect(() => {
    if (id) {
      fetchAssistant();
    }
  }, [id]);

  const fetchAssistant = async () => {
    try {
      const data = await getVAPIAssistant(id);
      setAssistant(data);
    } catch (error) {
      console.error('Error fetching assistant:', error);
    }
  };

   const handleSubmit = async (e) => {
    e.preventDefault();
    try {
/*       const formattedAssistant = Object.keys(assistant).reduce((acc, key) => {
        if (typeof assistant[key] === 'object' && assistant[key] !== null) {
          acc[key] = JSON.stringify(assistant[key]);
        } else {
          acc[key] = assistant[key];
        }
        return acc;
      }, {});
 */

      const formattedAssistant = {
        ...assistant,
      transcriber: typeof assistant.transcriber === 'string' ? assistant.transcriber : JSON.stringify(assistant.transcriber),
      model: typeof assistant.model === 'string' ? assistant.model : JSON.stringify(assistant.model),
      voice: typeof assistant.voice === 'string' ? assistant.voice : JSON.stringify(assistant.voice),
      clientMessages: typeof assistant.clientMessages === 'string' ? assistant.clientMessages : JSON.stringify(assistant.clientMessages),
      serverMessages: typeof assistant.serverMessages === 'string' ? assistant.serverMessages : JSON.stringify(assistant.serverMessages),
      transportConfigurations: typeof assistant.transportConfigurations === 'string' ? assistant.transportConfigurations : JSON.stringify(assistant.transportConfigurations),
      voicemailDetection: typeof assistant.voicemailDetection === 'string' ? assistant.voicemailDetection : JSON.stringify(assistant.voicemailDetection),
      endCallPhrases: typeof assistant.endCallPhrases === 'string' ? assistant.endCallPhrases : JSON.stringify(assistant.endCallPhrases),
      metadata: typeof assistant.metadata === 'string' ? assistant.metadata : JSON.stringify(assistant.metadata),
      analysisPlan: typeof assistant.analysisPlan === 'string' ? assistant.analysisPlan : JSON.stringify(assistant.analysisPlan),
      artifactPlan: typeof assistant.artifactPlan === 'string' ? assistant.artifactPlan : JSON.stringify(assistant.artifactPlan),
      messagePlan: typeof assistant.messagePlan === 'string' ? assistant.messagePlan : JSON.stringify(assistant.messagePlan),
      startSpeakingPlan: typeof assistant.startSpeakingPlan === 'string' ? assistant.startSpeakingPlan : JSON.stringify(assistant.startSpeakingPlan),
      stopSpeakingPlan: typeof assistant.stopSpeakingPlan === 'string' ? assistant.stopSpeakingPlan : JSON.stringify(assistant.stopSpeakingPlan),
      credentialIds: typeof assistant.credentialIds === 'string' ? assistant.credentialIds : JSON.stringify(assistant.credentialIds)
    };

      if (id) {
        await updateVAPIAssistant(id, formattedAssistant);
      } else {
        await createVAPIAssistant(formattedAssistant);
      }
      navigate('/vapi-assistants');
    } catch (error) {
      console.error('Error saving assistant:', error);
    }
  };
 /*
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        await updateVAPIAssistant(id, assistant);
      } else {
        await createVAPIAssistant(assistant);
      }
      navigate('/vapi-assistants');
    } catch (error) {
      console.error('Error saving assistant:', error);
    }
  };
*/

/* if (error) {
  return <Alert variant="danger">{error}</Alert>;
}
 */  return (
    <form onSubmit={handleSubmit}>
      <TextField
        name="name"
        label="Name"
        value={assistant.name}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        name="transcriber"
        label="Transcriber Configuration (JSON)"
        value={JSON.stringify(assistant.transcriber, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="model"
        label="Model Configuration (JSON)"
        value={JSON.stringify(assistant.model, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={8}
      />
      <TextField
        name="voice"
        label="Voice Configuration (JSON)"
        value={JSON.stringify(assistant.voice, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>First Message Mode</InputLabel>
        <Select
          name="firstMessageMode"
          value={assistant.firstMessageMode}
          onChange={handleChange}
        >
          <MenuItem value="assistant-speaks-first">Assistant Speaks First</MenuItem>
          <MenuItem value="user-speaks-first">User Speaks First</MenuItem>
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            checked={assistant.recordingEnabled}
            onChange={handleChange}
            name="recordingEnabled"
          />
        }
        label="Recording Enabled"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={assistant.hipaaEnabled}
            onChange={handleChange}
            name="hipaaEnabled"
          />
        }
        label="HIPAA Enabled"
      />
      <TextField
        name="clientMessages"
        label="Client Messages (JSON array)"
        value={JSON.stringify(assistant.clientMessages, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="serverMessages"
        label="Server Messages (JSON array)"
        value={JSON.stringify(assistant.serverMessages, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="silenceTimeoutSeconds"
        label="Silence Timeout (seconds)"
        type="number"
        value={assistant.silenceTimeoutSeconds}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        name="maxDurationSeconds"
        label="Max Duration (seconds)"
        type="number"
        value={assistant.maxDurationSeconds}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        name="backgroundSound"
        label="Background Sound"
        value={assistant.backgroundSound}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={assistant.backchannelingEnabled}
            onChange={handleChange}
            name="backchannelingEnabled"
          />
        }
        label="Backchanneling Enabled"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={assistant.backgroundDenoisingEnabled}
            onChange={handleChange}
            name="backgroundDenoisingEnabled"
          />
        }
        label="Background Denoising Enabled"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={assistant.modelOutputInMessagesEnabled}
            onChange={handleChange}
            name="modelOutputInMessagesEnabled"
          />
        }
        label="Model Output In Messages Enabled"
      />
      <TextField
        name="transportConfigurations"
        label="Transport Configurations (JSON)"
        value={JSON.stringify(assistant.transportConfigurations, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="firstMessage"
        label="First Message"
        value={assistant.firstMessage}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        name="voicemailDetection"
        label="Voicemail Detection (JSON)"
        value={JSON.stringify(assistant.voicemailDetection, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="voicemailMessage"
        label="Voicemail Message"
        value={assistant.voicemailMessage}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        name="endCallMessage"
        label="End Call Message"
        value={assistant.endCallMessage}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        name="endCallPhrases"
        label="End Call Phrases (JSON array)"
        value={JSON.stringify(assistant.endCallPhrases, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="metadata"
        label="Metadata (JSON)"
        value={JSON.stringify(assistant.metadata, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="serverUrl"
        label="Server URL"
        value={assistant.serverUrl}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        name="serverUrlSecret"
        label="Server URL Secret"
        value={assistant.serverUrlSecret}
        onChange={handleChange}
        fullWidth
        margin="normal"
        type="password"
      />
      <TextField
        name="analysisPlan"
        label="Analysis Plan (JSON)"
        value={JSON.stringify(assistant.analysisPlan, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="artifactPlan"
        label="Artifact Plan (JSON)"
        value={JSON.stringify(assistant.artifactPlan, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="messagePlan"
        label="Message Plan (JSON)"
        value={JSON.stringify(assistant.messagePlan, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="startSpeakingPlan"
        label="Start Speaking Plan (JSON)"
        value={JSON.stringify(assistant.startSpeakingPlan, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="stopSpeakingPlan"
        label="Stop Speaking Plan (JSON)"
        value={JSON.stringify(assistant.stopSpeakingPlan, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="credentialIds"
        label="Credential IDs (JSON array)"
        value={JSON.stringify(assistant.credentialIds, null, 2)}
        onChange={handleJsonChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
        {id ? 'Update' : 'Create'} Assistant
      </Button>
    </form>
  );
};

export default AssistantForm;