// CancelAppointment.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getAppointmentById, cancelAppointmentById } from '../services/api';
import { Form, Alert, Button, Spinner } from 'react-bootstrap';
import ThankYou from './ThankYou';

const CancelAppointment = () => {
    const { appointmentId } = useParams();
    const [appointment, setAppointment] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showThankYou, setShowThankYou] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        loadAppointmentDetails();
    }, [appointmentId]);

    const loadAppointmentDetails = async () => {
        try {
            console.log('loadAppointmentDetails');
            const data = await getAppointmentById(appointmentId);
            setAppointment(data);
            console.log('loadAppointmentDetails data'+data);
        } catch (error) {
            setError('Failed to load appointment details');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = async () => {
        if (!window.confirm('Are you sure you want to cancel this appointment?')) {
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            await cancelAppointmentById(appointmentId, { message });
            setShowThankYou(true);
            //alert('Appointment cancelled successfully');
        } catch (error) {
            setError('Failed to cancel appointment');
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <Spinner animation="border" />;
    }

    if (error) {
        return <Alert variant="danger">{error}</Alert>;
    }

    if (!appointment) {
        return <Alert variant="warning">Appointment not found</Alert>;
    }

    if (showThankYou) {
        return <ThankYou type="cancelled" appointment={appointment} />;
    }

    return (
        <div className="cancel-appointment">
            <h2>Cancel Appointment</h2>
            <p>Are you sure you want to cancel your appointment scheduled for:</p>
            <p className="appointment-time">
                {new Date(appointment.appointmentDateTime).toLocaleString()}
            </p>
            <Form.Group className="mb-3">
                        <Form.Label>Cancellation Reason (Optional)</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Please let us know why you're cancelling"
                        />
            </Form.Group>
            <Button 
                variant="danger"
                onClick={handleCancel}
                disabled={isLoading}
            >
                {isLoading ? 'Cancelling...' : 'Cancel Appointment'}
            </Button>
        </div>
    );
};

export default CancelAppointment;